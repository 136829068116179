.pt-header-content {
  display: flex;
  flex-direction: column;
  position: sticky;
  z-index: z-index(under-header);
  top: 28px;
  left: 0;
  gap: $sp-8;
  padding-top: $sp-16;
  background-color: var(--background-color-1);

  @include breakpoint(md) {
    padding-top: $sp-24;
    top: 92px;
  }

  @include breakpoint(lg) {
    top: 80px;
  }
}
