.chart-tooltip-container {
  pointer-events: none;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .chart-tooltip {
    position: absolute;
    transform: translate(-50%, -100%);
    padding: $sp-8;
    background-color: var(--color-chart-tooltip-bg);
    @include font-regular-10;
  }
}
