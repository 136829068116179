@import 'themes/pt/theme';
@import '@pinup-teams/common/styles/utilities';

:root, .pt-mfe-shell {
  --bg-texture-dark-url: url("/assets/images/texture-bg-dark.svg");
  --bg-texture-light-url: url("/assets/images/texture-bg-light.svg");
}

@font-face {
  font-family: "Brand";
  src: url("/assets/fonts/Brand/Brand.eot");
  src: url("/assets/fonts/Brand/Brand.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/Brand/Brand.woff2") format("woff2"),
  url("/assets/fonts/Brand/Brand.woff") format("woff"),
  url("/assets/fonts/Brand/Brand.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.pt-logo {
  svg {
    max-height: rem(36px);

    @include breakpoint(lg) {
      max-height: rem(42px);
    }
  }
}
