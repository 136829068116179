* {
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: $sp-4;
  height: $sp-4;
}

*::-webkit-scrollbar-track {
  background-color: var(--background-color-1x);
}

*::-webkit-scrollbar-thumb {
  border-radius: 0.75em;
  background-color: var(--background-color-5);
}
