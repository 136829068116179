.pu-admin-top-bar {
  position: sticky;
  z-index: 1;
  top: 64px;
  padding-top: $sp-16;
  margin-top: -$sp-16;
  z-index: z-index(under-header);
  background-color: var(--background-color-1);

  @include breakpoint(md) {
    padding-top: $sp-24;
    margin-top: -$sp-24;
    top: 230px;
  }

  @include breakpoint(lg) {
    top: 174px;
  }

  &::after {
    content: '';
    position: absolute;
    height: $sp-16;
    left: 0;
    bottom: -$sp-16;
    width: 100%;
    background: var(--gradient-shadow);

    @include breakpoint(md) {
      bottom: -$sp-24;
      height: $sp-24;
    }
  }
}
