.pu-admin__header {
  padding-top: $sp_4 !important;
  top: rem(-84px) !important;

  @include breakpoint(lg) {
    top: rem(78px) !important;
  }
}

.pu-admin-top-bar {
  top: rem(66px) !important;

  @include breakpoint(lg) {
    top: rem(168px) !important;
  }
}
