.mat-mdc-checkbox {
  .mdc-checkbox__background {
    border: 1px solid var(--pu-checkbox-br) !important;
    border-radius: $rd-4;

    svg {
      width: $sp-12;
      height: $sp-12;
      margin: auto;
    }
  }

  &-checked {
    .mdc-checkbox__background {
      border: 0 !important;
      box-shadow: 1px 1px 2px 0px rgba(13, 16, 22, 0.40) inset;
      background-color: var(--pu-checkbox-bg-active) !important;
    }
  }
}
