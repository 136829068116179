@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

$ff-roboto: "Roboto", sans-serif;
$ff-brand: "Brand", sans-serif;

:root, .pt-mfe-shell {
  --ff-roboto: #{$ff-roboto};
  --ff-brand: #{$ff-brand};

  // PU Empty
  --pu-empty-ff: #{$ff-roboto};
}
