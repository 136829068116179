@import 'typography';

// -------------------------------------
// usage:
// @import '@pu/assets/utilities/geometry'
// border-radius: $rd-4
// -------------------------------------
$rd-0: 0;
$rd-1: rem(1px);
$rd-2: rem(2px);
$rd-4: rem(4px);
$rd-8: rem(8px);
$rd-16: rem(16px);
