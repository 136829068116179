@import 'typography';

$sp-0: 0;
$sp-2: rem(2px);
$sp-4: rem(4px);
$sp-6: rem(6px);
$sp-8: rem(8px);
$sp-10: rem(10px);
$sp-12: rem(12px);
$sp-16: rem(16px);
$sp-20: rem(20px);
$sp-24: rem(24px);
$sp-28: rem(28px);
$sp-32: rem(32px);
$sp-36: rem(36px);
$sp-40: rem(40px);
$sp-48: rem(48px);
$sp-56: rem(56px);
$sp-64: rem(64px);
$sp-72: rem(72px);

$_spacings: (
  sp0: $sp-0,
  sp2: $sp-2,
  sp4: $sp-4,
  sp6: $sp-6,
  sp8: $sp-8,
  sp10: $sp-10,
  sp12: $sp-12,
  sp16: $sp-16,
  sp20: $sp-20,
  sp24: $sp-24,
  sp32: $sp-32,
  sp36: $sp-36,
  sp40: $sp-40,
  sp48: $sp-48,
  sp56: $sp-56,
  sp64: $sp-64,
  sp72: $sp-72
);

// -------------------------------------
// Mixin for paddings
// @mixin: padding($top, $right, $bottom, $left)
// -------------------------------------
// usage:
// @import '@pu/assets/utilities/spacing'
// @include padding(sp4, 13px, sp16)
// -------------------------------------
@mixin padding($top, $right: false, $bottom: false, $left: false) {
  @if ($top) {
    @if map-has-key($_spacings, $top) {
      padding-top: map-get($_spacings, $top);
    } @else {
      padding-top: rem($top);
    }
  }
  @if ($right) {
    @if map-has-key($_spacings, $right) {
      padding-right: map-get($_spacings, $right);
    } @else {
      padding-right: rem($right);
    }
  }
  @if ($bottom) {
    @if map-has-key($_spacings, $bottom) {
      padding-bottom: map-get($_spacings, $bottom);
    } @else {
      padding-bottom: rem($bottom);
    }
  }
  @if ($left) {
    @if map-has-key($_spacings, $left) {
      padding-left: map-get($_spacings, $left);
    } @else {
      padding-left: rem($left);
    }
  }
}

// -------------------------------------
// Mixin for margins
// @mixin: margin($top, $right, $bottom, $left)
// -------------------------------------
// usage:
// @import '@pu/assets/utilities/spacing'
// @include margin(sp4, 13px, sp16)
// -------------------------------------
@mixin margin($top, $right: false, $bottom: false, $left: false) {
  @if ($top) {
    @if map-has-key($_spacings, $top) {
      margin-top: map-get($_spacings, $top);
    } @else {
      margin-top: rem($top);
    }
  }
  @if ($right) {
    @if map-has-key($_spacings, $right) {
      margin-right: map-get($_spacings, $right);
    } @else {
      margin-right: rem($right);
    }
  }
  @if ($bottom) {
    @if map-has-key($_spacings, $bottom) {
      margin-bottom: map-get($_spacings, $bottom);
    } @else {
      margin-bottom: rem($bottom);
    }
  }
  @if ($left) {
    @if map-has-key($_spacings, $left) {
      margin-left: map-get($_spacings, $left);
    } @else {
      margin-left: rem($left);
    }
  }
}
