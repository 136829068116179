@mixin container {
  @include padding(sp0, sp12, 68px, sp12);

  @include breakpoint(md) {
    @include padding(sp0, sp16, 78px, sp16);
  }

  @include breakpoint(lg) {
    padding: $sp-0 $sp-32 $sp-0 $sp-32;
  }
}

// Container general
.container {
  @include container;
}

// Container from md only
@include breakpoint(md) {
  .md\:container {
    @include container;
  }
}

// Container xl only
@include breakpoint(xl) {
  .xl\:container {
    @include container;
  }
}
