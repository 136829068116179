// -------------------------------------
// usage:
// @import '@pu/assets/utilities/animation'
// transition: $transition-2e
// -------------------------------------
$transition-0: 0s;
$transition-2l: 0.2s linear;
$transition-2e: 0.2s ease-in-out;
$transition-3l: 0.3s linear;
$transition-6l: 0.6s;

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}
