@use "sass:math";

$base-font-size: 16px !default;

// -------------------------------------
// Function for converting to rem
// -------------------------------------
// usage:
// @import '@pu/assets/typography'
// top: rem(100px)
// -------------------------------------
@function rem($px) {
  @return math.div($px, $base-font-size) * 1rem;
}

@mixin font-regular-10 {
  font-size: rem(10px);
  line-height: rem(14px);
  font-weight: 400;
}

@mixin font-medium-10 {
  @include font-regular-10;
  font-weight: 500;
}

@mixin font-bold-10 {
  @include font-regular-10;
  font-weight: 700;
}

@mixin font-regular-12 {
  font-size: rem(12px);
  line-height: rem(16px);
  font-weight: 400;
}

@mixin font-medium-12 {
  @include font-regular-12;
  font-weight: 500;
}

@mixin font-bold-12 {
  @include font-regular-12;
  font-weight: 700;
}

@mixin font-regular-14 {
  font-size: rem(14px);
  line-height: rem(18px);
  font-weight: 400;
}

@mixin font-medium-14 {
  @include font-regular-14;
  font-weight: 500;
}

@mixin font-bold-14 {
  @include font-regular-14;
  font-weight: 700;
}

@mixin font-regular-16 {
  font-size: rem(16px);
  line-height: rem(22px);
  font-weight: 400;
}

@mixin font-medium-16 {
  @include font-regular-16;
  font-weight: 500;
}

@mixin font-bold-16 {
  @include font-regular-16;
  font-weight: 700;
}

@mixin font-regular-18 {
  font-size: rem(18px);
  line-height: rem(24px);
  font-weight: 400;
}

@mixin font-medium-18 {
  @include font-regular-18;
  font-weight: 500;
}

@mixin font-bold-18 {
  @include font-regular-18;
  font-weight: 700;
}

@mixin font-regular-20 {
  font-size: rem(20px);
  line-height: rem(26px);
  font-weight: 400;
}

@mixin font-medium-20 {
  @include font-regular-20;
  font-weight: 500;
}

@mixin font-bold-20 {
  @include font-regular-20;
  font-weight: 700;
}

@mixin font-bold-24 {
  font-size: rem(24px);
  line-height: rem(30px);
  font-weight: 700;
}

@mixin font-regular-28 {
  font-size: rem(28px);
  line-height: rem(34px);
  font-weight: 400;
}

@mixin font-medium-28 {
  @include font-regular-28;
  font-weight: 500;
}

@mixin font-bold-28 {
  font-size: rem(28px);
  line-height: rem(36px);
  font-weight: 700;
}

@mixin font-bold-32 {
  font-size: rem(32px);
  line-height: rem(42px);
  font-weight: 700;
}
