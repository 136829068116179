@import '@pinup-teams/common/styles/utilities';

.pu-table {
  overflow: auto;
}

.mat-mdc-table {
  @include font-regular-14;

  .mat-mdc {
    &-header-row {
      @include font-medium-14;
      color: var(--text-icon-color-4);
    }

    &-header-cell {
      white-space: nowrap;
      background: var(--color-table-cell-header-bg) !important;

      &:first-child {
        border-radius: $rd-8 0 0 0;
      }

      &:last-child {
        border-radius: 0 $rd-8 0 0;
      }
    }

    &-header-row {
      height: 42px;
    }

    &-cell,
    &-header-cell {
      border-bottom: none;

      &:not(:last-child) {
        border-right: 1px solid var(--background-color-2);
      }

      &:first-of-type {
        padding-left: 16px;
      }

      &:last-of-type {
        padding-right: 16px;
      }
    }

    &-row {
      color: var(--text-icon-color-1);

      &:nth-child(odd) {
        .mat-mdc-cell {
          background-color: var(--color-table-cell-odd-bg);

          &.mat-mdc-cell_dialog {
            background-color: var(--color-table-cell-dialog-odd-bg);
          }
        }
      }

      &:last-child {
        .mat-mdc-cell:first-child {
          border-radius: 0 0 0 $rd-8;
        }

        .mat-mdc-cell:last-child {
          border-radius: 0 0 $rd-8 0;
        }
      }
    }

    &-cell {
      background-color: var(--color-table-cell-bg);

      &.mat-mdc-cell_dialog {
        background-color: var(--color-table-cell-dialog-bg);
      }
    }
  }

  .mat-column-image {
    height: inherit;

    img {
      max-height: 100%;
      margin: 0 auto;
    }
  }
}
