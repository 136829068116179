@import '@pinup-teams/common/styles/utilities';
@import '@pinup-teams/common/styles/ui/icon';

:host {
  position: relative;
  color: var(--text-icon-color-5);
}

.nav-item {
  @include button-reset();
  background-color: transparent;

  display: grid;
  text-align: center;
  grid-gap: 2px;
  position: relative;
  text-decoration: none;
  padding: $sp-12 0 $sp-16;
  color: unset;
  @include font-regular-12;

  @include breakpoint(sm) {
    grid-gap: 0;
  }

  @include breakpoint(md) {
    @include font-regular-14;
  }

  &:active,
  &:hover,
  &:focus {
    color: var(--text-icon-color-1);
  }

  &__icon {
    color: unset;
  }

  &_active {
    color: var(--text-icon-color-1);
  }
}

:host-context(.pu-sidebar__nav) {
  .nav-item {
    display: flex;
    align-items: center;
    width: 100%;
    gap: $sp-12;
    padding: $sp-6 0;
    @include font-regular-14;

    @include breakpoint(xl) {
      @include font-regular-16;
    }

    &__list {
      padding-left: $sp-36;
    }
  }
}

:host-context(.pu-menu__nav) {
  .nav-item {
    padding: $sp-8 0;
    @include font-medium-14;

    @include breakpoint(sm) {
      padding: $sp-12 0;
      @include font-medium-18;
    }

    @include breakpoint(md) {
      @include font-medium-20;
    }
  }
}

pu-expansion-panel.nav-expansion-panel {
  padding: 0;

  &:hover {
    color: var(--text-icon-color-1);

    .pu-expansion-panel__toggle-btn {
      color: unset;
    }
  }

  .nav-item_active ~ .pu-expansion-panel__toggle-btn {
    color: var(--text-icon-color-1);
  }
}
