@import '../utilities';

.pu-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  &-svg {
    width: calc(var(--pu-icon-size) * 1px);
    height: calc(var(--pu-icon-size) * 1px);
  }
}

@include breakpoint(md) {
  .md\:pu-icon {
    &_m .pu-icon-svg {
      --pu-icon-size: 24;
    }

    &_l .pu-icon-svg {
      --pu-icon-size: 32;
    }
  }
}
