@import 'palette';

:root, .pt-mfe-shell {
  color-scheme: light;

  // Brand colors
  --color-brand-primary: #{$brand-primary};
  --color-brand-primary-2: #{$primary-shades-darker-1};
  --color-brand-primary-3: #{$primary-shades-darker-2};
  --color-brand-secondary: #{$brand-secondary};

  // Neutral background colors
  --background-color-1: #{$neutrals-light-theme-color-1};
  --background-color-1x: #{$neutrals-light-theme-color-1x};
  --background-color-2: #{$neutrals-light-theme-color-2};
  --background-color-3: #{$neutrals-light-theme-color-3};
  --background-color-3x: #{$neutrals-light-theme-color-3x};
  --background-color-4: #{$neutrals-light-theme-color-4};
  --background-color-5: #{$neutrals-light-theme-color-5};
  --background-color-6: #{$neutrals-light-theme-color-6};
  --background-color-7: #{$neutrals-light-theme-color-7};
  --background-color-8: #{$neutrals-light-theme-color-8};
  --background-color-9: #{$neutrals-light-theme-color-9};
  --background-color-10: #{$neutrals-light-theme-color-10};

  // Neutral text and icon colors
  --text-icon-color-1: #{$neutrals-light-theme-color-9};
  --text-icon-color-2: #{$neutrals-light-theme-color-9};
  --text-icon-color-3: #{$neutrals-light-theme-color-8};
  --text-icon-color-4: #{$neutrals-light-theme-color-7};
  --text-icon-color-5: #{$neutrals-light-theme-color-5};
  --text-icon-color-6: #{$neutrals-light-theme-color-5};
  --text-icon-color-7: #{$neutrals-light-theme-color-3};
  --text-icon-color-8: #{$neutrals-light-theme-color-3};
  --text-icon-color-9: #{$neutrals-light-theme-color-2};

  // Transparent colors
  --color-transparent-90: #{$transparent-light-theme-color-90};
  --color-transparent-75: #{$transparent-light-theme-color-75};
  --color-transparent-50: #{$transparent-light-theme-color-50};
  --color-transparent-overlay: #{$transparent-light-theme-color-popup-bg};

  // Additional colors
  --color-additionals-info: #{$other-blue};
  --color-additionals-success: #{$other-green};
  --color-additionals-warning: #{$other-yellow};
  --color-additionals-danger: #{$other-red};

  // Status colors
  --color-status-1: #{$other-blue};
  --color-status-2: #{$other-green};
  --color-status-3: #{$other-yellow};
  --color-status-4: #{$other-red};
  --color-status-5: #{$other-purple};
  --color-status-6: #{$other-dark-fuchsia};
  --color-status-7: #{$neutrals-light-theme-color-7};

  // Donut colors
  --color-donut-1: #{$other-red};
  --color-donut-2: #{$other-green};
  --color-donut-3: #{$other-blue};
  --color-donut-4: #{$other-yellow};
  --color-donut-5: #{$other-dark-fuchsia};

  // Progress gradient colors
  --color-progress-1-start: #{$other-blue};
  --color-progress-1-end: #{$other-light-blue};
  --color-progress-2-start: #{$other-yellow};
  --color-progress-2-end: #{$other-red};
  --color-progress-3-start: #{$other-purple};
  --color-progress-3-end: #{$other-red};
  --color-progress-4-start: #{$other-green};
  --color-progress-4-end: #{$other-green};
  --color-progress-5-start: #{$other-red};
  --color-progress-5-end: #{$other-red};

  // Gradients
  --gradient-border-hover: linear-gradient(
      139.58deg,
      #{$brand-secondary} 0%,
      #{rgba($brand-secondary, 0)} 100%
  );
  --gradient-tab-highlighting: linear-gradient(
      90deg,
      #{rgba($brand-primary, 0)} 0%,
      #{$brand-primary} 50.52%,
      #{rgba($brand-primary, 0)} 100%
  );
  --gradient-balance-skin-border: linear-gradient(
      139.58deg,
      #{$brand-primary} 0%,
      #{rgba($brand-primary, 0)} 100%
  );
  --gradient-calendar-today-date-border: linear-gradient(
      113.11deg,
      #{$brand-secondary} 0%,
      #{$neutrals-light-theme-color-7} 100%
  );

  --gradient-slider-controls-prev-1: linear-gradient(
      90deg,
      #{$neutrals-light-theme-color-1} 0%,
      #{rgba($neutrals-light-theme-color-1, 0)} 100%
  );
  --gradient-slider-controls-next-1: linear-gradient(
      270deg,
      #{$neutrals-light-theme-color-1} 0%,
      #{rgba($neutrals-light-theme-color-1, 0)} 100%
  );
  --gradient-slider-controls-prev-2: linear-gradient(
      90deg,
      #{$neutrals-light-theme-color-2} 0%,
      #{$neutrals-light-theme-color-2} 10%,
      #{rgba($neutrals-light-theme-color-2, 0)} 100%
  );
  --gradient-slider-controls-next-2: linear-gradient(
      270deg,
      #{$neutrals-light-theme-color-2} 0%,
      #{$neutrals-light-theme-color-2} 10%,
      #{rgba($neutrals-light-theme-color-2, 0)} 100%
  );

  --gradient-divider-horizontal: linear-gradient(
      90deg,
      #{rgba($neutrals-light-theme-color-2, 0)} 0%,
      #{rgba($neutrals-light-theme-color-5, 0.252417)} 27.6%,
      #{rgba($neutrals-light-theme-color-5, 0.5)} 52.62%,
      #{rgba($neutrals-light-theme-color-5, 0.291283)} 74.48%,
      #{rgba($neutrals-light-theme-color-1, 0)} 100%
  );
  --gradient-divider-vertical: linear-gradient(
      180deg,
      #{rgba($neutrals-light-theme-color-2, 0)} 0%,
      #{rgba($neutrals-light-theme-color-5, 0.252417)} 27.6%,
      #{rgba($neutrals-light-theme-color-5, 0.5)} 52.62%,
      #{rgba($neutrals-light-theme-color-5, 0.291283)} 74.48%,
      #{rgba($neutrals-light-theme-color-1, 0)} 100%
  );
  --gradient-divider-survey: linear-gradient(
      180deg,
      #{rgba($neutrals-light-theme-color-2, 0)} 0%,
      #{rgba($neutrals-light-theme-color-5, 0.15145)} 27.6%,
      #{rgba($neutrals-light-theme-color-5, 0.3)} 52.62%,
      #{rgba($neutrals-light-theme-color-5, 0.17477)} 74.48%,
      #{rgba($neutrals-light-theme-color-1, 0)} 100%
  );

  --gradient-shadow: linear-gradient(
      180deg,
      #{$neutrals-light-theme-color-1} 26.76%,
      #{rgba($neutrals-light-theme-color-1, 0)} 98.32%
  );
  --gradient-shadow-rev: linear-gradient(
      0deg,
      #{$neutrals-light-theme-color-1} 26.76%,
      #{rgba($neutrals-light-theme-color-1, 0)} 98.32%
  );
  --gradient-shadow-to-left: linear-gradient(
      -90deg,
      #{$neutrals-light-theme-color-1} 0%,
      #{rgba($neutrals-light-theme-color-1, 0)} 100%
  );
  --gradient-shadow-to-top: linear-gradient(
      0deg,
      #{$neutrals-light-theme-color-1} 0%,
      #{rgba($neutrals-light-theme-color-1, 0)} 100%
  );

  --gradient-sub-category-tab-br-active: #{$neutrals-light-theme-color-7};
  --gradient-sub-category-tab-bg: #{$neutrals-light-theme-color-7};

  --gradient-toggle-circle: linear-gradient(
      141.95deg,
      #{$neutrals-light-theme-color-4} 25.7%,
      #{$neutrals-dark-theme-color-5} 100%
  );
  --gradient-toggle-circle-active: linear-gradient(
      141.95deg,
      #{$brand-secondary} 25.7%,
      #{$secondary-shades-darker-2} 100%
  );
  --gradient-toggle-circle-bg: linear-gradient(
      144.73deg,
      #{$neutrals-light-theme-color-3x} 15.19%,
      #{$neutrals-light-theme-color-2} 56.91%
  );

  --gradient-user-card-border-0: linear-gradient(
      139.58deg,
      #{$other-green} 0%,
      #{$other-blue} 100%
  );
  --gradient-user-card-border-1: linear-gradient(
      139.58deg,
      #{$other-dark-purple} 0%,
      #{$other-fuchsia} 100%
  );
  --gradient-user-card-border-2: linear-gradient(
      139.58deg,
      #{$other-green} 0%,
      #{$other-blue} 100%
  );
  --gradient-user-card-border-3: linear-gradient(
      139.58deg,
      #{$other-orange} 0%,
      #{$other-red} 100%
  );

  // Shadows
  --shadow-color-0: #{rgba($neutrals-light-theme-color-10, 0.18)};
  --shadow-color-1: #{rgba($neutrals-light-theme-color-10, 0.4)};
  --shadow-dropdown: 0px 0px 4px #{rgba($neutrals-light-theme-color-10, 0.2)};
  --shadow-sidebar: 4px 0px 10px #{rgba($neutrals-light-theme-color-8, 0.12)};
  --shadow-navbar: 0px -4px 10px #{rgba($neutrals-light-theme-color-10, 0.12)};
  --shadow-header-top: 0px 4px 10px #{rgba($neutrals-light-theme-color-8, 0.1)};
  --shadow-bottom-light: 1px 1px 3px #{rgba($neutrals-light-theme-color-8, 0.15)};
}
