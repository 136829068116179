.pu-scroll-shadow,
.pu-scroll-shadow-small,
.pu-shadow-dialog {
  display: flex;
  position: absolute;
  z-index: -2;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--gradient-shadow);
  transition: bottom $transition-6l;
}

.pu-scroll-shadow {
  height: rem(60px);

  @include breakpoint(lg) {
    height: rem(80px);
  }

  &_visible {
    bottom: -60px;

    @include breakpoint(lg) {
      bottom: -80px;
    }
  }
}

.pu-scroll-shadow-small {
  height: rem(35px);

  @include breakpoint(lg) {
    height: rem(55px);
  }

  &_visible {
    bottom: rem(-35px);

    @include breakpoint(lg) {
      bottom: rem(-55px);
    }
  }
}

.pu-shadow-dialog {
  height: rem(20px);

  &_visible {
    bottom: -20px;
  }
}
