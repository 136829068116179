@import '@pinup-teams/common/styles/utilities';

.pu-back-btn {
  a {
    display: block;
    @include font-regular-14;
    color: var(--text-icon-color-5);
    padding-left: 5px;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;

    &:hover {
      color: var(--text-icon-color-3);
    }

    &:before {
      display: inline-block;
      content: "";
      width: 8px;
      height: 8px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      margin-right: 5px;
      border-color: var(--text-icon-color-3);
      transform: rotate(45deg);
    }
  }
}
