:root.pu-dark-theme, .pt-mfe-shell.pu-dark-theme {
  color-scheme: dark;
  color: var(--text-icon-color-1);
  background: var(--background-color-1);

  --color-border: var(--background-color-2);
  --color-filled-txt: var(--text-icon-color-1);
  --color-datepicker-date-active-text: var(--text-icon-color-1);

  // Balance
  --color-balance-txt: var(--text-icon-color-6);
  --color-balance-horizontal-txt: var(--text-icon-color-1);
  --color-balance-icon: var(--text-icon-color-4);
  --color-balance-bg: var(--background-color-1);
  --color-balance-skin-share-bg: var(--background-color-3);

  // Theme switcher
  --color-theme-switcher-border: var(--background-color-8);
  --color-theme-switcher-slider-bg: transparent;
  --color-theme-switcher-icon: var(--text-icon-color-1);

  // slide toggle
  --color-slide-toggle-track-active: var(--color-brand-secondary);
  --color-slide-toggle-track: var(--background-color-4);
  --color-slide-toggle-handle-bg: var(--background-color-10);
  --color-slide-toggle-handle-border: var(--background-color-6);
  --color-slide-toggle-handle-border-active: var(--color-brand-secondary);
  --shadow-slide-toggle-track: inset -1px 1px 2px var(--shadow-color-1);

  // Mat slide toggle
  --mdc-switch-disabled-label-text-color: var(--text-icon-color-5);

  // Product
  --color-product-color-selected: var(--text-icon-color-1);

  // Cart
  --color-cart-form-tab: var(--background-color-4);

  // Admin Table
  --color-table-cell-header-bg: var(--background-color-3);
  --color-table-cell-odd-bg: var(--background-color-1x);
  --color-table-cell-bg: var(--background-color-2);
  --color-table-cell-dialog-header-bg: var(--background-color-3);
  --color-table-cell-dialog-odd-bg: var(--background-color-1x);
  --color-table-cell-dialog-bg: var(--background-color-2);

  // Statuses
  --color-status-new: var(--color-status-1);
  --color-status-inProgress: var(--color-status-1);
  --color-status-confirmed: var(--color-status-3);
  --color-status-shipping: var(--color-status-3);
  --color-status-delivered: var(--color-status-3);
  --color-status-inReview: var(--color-status-3);
  --color-status-notPassed: var(--color-status-3);
  --color-status-completed: var(--color-status-2);
  --color-status-auto: var(--color-status-2);
  --color-status-approved: var(--color-status-2);
  --color-status-open: var(--color-status-2);
  --color-status-canceled: var(--color-status-4);
  --color-status-rejected: var(--color-status-5);
  --color-status-request: var(--color-status-6);

  // User statuses
  --color-status-probation: var(--color-status-3);
  --color-status-maternity: var(--color-status-3);
  --color-status-paternity: var(--color-status-3);
  --color-status-active: var(--color-status-2);
  --color-status-inactive: var(--color-status-4);
  --color-status-offboarding: var(--color-status-4);
  --color-status-deleted: var(--color-status-4);
  --color-status-archived: var(--color-status-7);
  --color-status-noJira: var(--color-status-7);

  // Message statuses
  --pt-color-status-scheduled: var(--color-status-3);
  --pt-color-status-sent: var(--color-status-2);

  // Courses statuses
  --color-course-status-not-startded: var(--color-status-3);
  --color-course-status-completed: var(--color-status-2);
  --color-course-status-not-completed: var(--color-status-4);
  --color-course-status-in-progress: var(--color-status-1);

  // Survey statuses
  --color-status-finished: var(--color-status-4);
  --color-status-planned: var(--color-status-3);

  // Circle progress
  --color-circle-progress-default: var(--background-color-1);

  // Quest progress gradient colors
  --color-quest-progress-in-progress-start: var(--color-progress-1-start);
  --color-quest-progress-in-progress-end: var(--color-progress-1-end);
  --color-quest-progress-in-review-start: var(--color-progress-2-start);
  --color-quest-progress-in-review-end: var(--color-progress-2-end);
  --color-quest-progress-rejected-start: var(--color-progress-3-start);
  --color-quest-progress-rejected-end: var(--color-progress-3-end);
  --color-quest-progress-completed-start: var(--color-progress-4-start);
  --color-quest-progress-completed-end: var(--color-progress-4-end);
  --color-quest-progress-canceled-start: var(--color-progress-5-start);
  --color-quest-progress-canceled-end: var(--color-progress-5-end);

  // Course progress gradient colors
  --color-course-progress-in-progress-start: var(--color-progress-1-start);
  --color-course-progress-in-progress-end: var(--color-progress-1-end);
  --color-course-progress-not-passed-start: var(--color-progress-2-start);
  --color-course-progress-not-passed-end: var(--color-progress-2-end);
  --color-course-progress-completed-start: var(--color-progress-4-start);
  --color-course-progress-completed-end: var(--color-progress-4-end);

  // Avatar
  --color-avatar-border: var(--background-color-8);
  --color-avatar-bg: var(--background-color-3);
  --color-avatar-text: var(--text-icon-color-1);
  --color-avatar-colorful-text: var(--text-icon-color-1);
  --color-avatar-colorful-border: var(--background-color-8);
  --brightness-avatar-colorful-bg: 40%;

  // Birthday calendar
  --color-day-date: var(--text-icon-color-2);
  --color-day-month: var(--text-icon-color-3);
  --color-user-txt: var(--text-icon-color-5);
  --color-user-txt-hover: var(--text-icon-color-2);
  --color-user-icon-notify: var(--text-icon-color-2);
  --color-user-icon-text: var(--text-icon-color-1);
  --color-user-card-bg: var(--background-color-3);
  --color-user-card-btn-hover: var(--color-brand-primary);
  --color-user-card-border-0: var(--gradient-user-card-border-0);
  --color-user-card-border-1: var(--gradient-user-card-border-1);
  --color-user-card-border-2: var(--gradient-user-card-border-2);
  --color-user-card-border-3: var(--gradient-user-card-border-3);
  --color-user-card-shadow: none;

  // Timer
  --color-timer-number: var(--text-icon-color-2);
  --color-timer-text: var(--text-icon-color-6);
  --color-timer-expired-number: var(--text-icon-color-6);
  --color-timer-expired-text: var(--text-icon-color-1);

  // Org tree
  --color-orgtree-header-bg: var(--color-transparent-75);
  --color-orgtree-mobile-header-bg: var(--background-color-1);
  --color-orgtree-mobile-menu-header-bg: var(--background-color-2);
  --color-orgtree-link: var(--text-icon-color-5);
  --color-orgtree-position: var(--text-icon-color-1);
  --color-orgtree-position-hover: var(--color-brand-secondary);
  --color-orgtree-position-bg: var(--background-color-1);
  --color-orgtree-avatar-border: var(--color-avatar-colorful-border);
  --color-orgtree-avatar-border-hover: var(--color-brand-secondary);
  --color-orgtree-avatar-text: var(--color-avatar-colorful-text);
  --color-orgtree-details-header-bg: var(--background-color-3);
  --color-orgtree-details-body-bg: var(--background-color-2);
  --color-orgtree-popover-bg: var(--background-color-3);

  // Texture
  --texture-url: var(--bg-texture-dark-url);

  // Colleague
  --color-colleague-bday-ntf-btn-active: var(--text-icon-color-2);

  // Signin
  --color-signin-btn-bg: var(--background-color-3);

  // Onboarding
  --pt-onboarding-stage-txt-1: var(--text-icon-color-1);
  --pt-onboarding-stage-txt-2: var(--text-icon-color-5);
  --pt-onboarding-stage-bg-1: var(--background-color-3);
  --pt-onboarding-stage-bg-2: var(--background-color-2);
  --pt-onboarding-stage-br-1: var(--background-color-8);

  // Courses
  --request-created-text: var(--text-icon-color-5);

  // Education events
  --table-header-bg: var(--background-color-3);
  --pt-user-manual-shadow: color-mix(in oklab, var(--background-color-4) 25%, transparent);

  // Surveys Analytics
  --color-donut-chart-label: var(--text-icon-color-2);
  --color-chart-tooltip-bg: var(--background-color-4);
  --color-chart-grid: var(--background-color-4);
  --color-grid-question-header: var(--background-color-4);

  // User survey
  --color-user-survey-question-text: var(--text-icon-color-3);
  --color-user-survey-description: var(--text-icon-color-3);
  --color-user-survey-even-row: var(--color-transparent-75);
  --color-user-survey-odd-row: var(--color-transparent-50);

  // Office visits details
  --color-table-border: var(--background-color-4);

  // Comments
  --color-comment-form-bg: var(--background-color-2);
}
