@import '@pinup-teams/common/styles/utilities';

.pu-breadcrumbs {
  display: flex;
  gap: $sp-4;
  @include font-regular-12;

  &__item {
    text-decoration: none;
    cursor: pointer;
    color: var(--text-icon-color-3);
    max-width: 300px;
    @include text-ellipsis;

    &:hover {
      color: var(--text-icon-color-1);
    }
  }

  &__separator {
    color: var(--text-icon-color-3);
  }

  &__tail {
    max-width: 300px;
    @include text-ellipsis;
    color: var(--text-icon-color-5);
  }
}
