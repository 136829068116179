@import 'froala-editor/css/froala_editor.pkgd.min.css';
@import 'froala-editor/css/froala_style.min.css';
@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
.pu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pu-icon-svg {
  width: calc(var(--pu-icon-size) * 1px);
  height: calc(var(--pu-icon-size) * 1px);
}

@media (min-width: 768px) {
  .md\:pu-icon_m .pu-icon-svg {
    --pu-icon-size: 24;
  }
  .md\:pu-icon_l .pu-icon-svg {
    --pu-icon-size: 32;
  }
}
@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
.pu-button {
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  background-color: none;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  letter-spacing: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  gap: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  font-weight: 500;
  padding-top: 0.3125rem;
  padding-right: 1rem;
  padding-bottom: 0.3125rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.25rem;
  transition: 0.2s ease-in-out;
  color: var(--pu-btn-txt);
}
.pu-button::-moz-focus-inner {
  border: 0;
}
.pu-button > *, .pu-button > *:before, .pu-button > *:after {
  box-sizing: inherit;
}
.pu-button .pu-icon-svg.m {
  width: 12px;
  height: 12px;
}
.pu-button_s {
  gap: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  font-weight: 500;
  padding-top: 0.3125rem;
  padding-right: 1rem;
  padding-bottom: 0.3125rem;
  padding-left: 1rem;
}
.pu-button_s .pu-icon-svg.m {
  width: 12px;
  height: 12px;
}
.pu-button_m {
  gap: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  font-weight: 500;
  padding-top: 0.4375rem;
  padding-right: 1.5rem;
  padding-bottom: 0.4375rem;
  padding-left: 1.5rem;
}
.pu-button_m .pu-icon-svg.m {
  width: 20px;
  height: 20px;
}
.pu-button_m.pu-button_only-icon {
  padding: 6px;
}
.pu-button_m.pu-button_only-icon .pu-icon-svg.m {
  width: 24px;
  height: 24px;
}
.pu-button_l {
  gap: 0.5rem;
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 400;
  font-weight: 500;
  padding-top: 0.5625rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5625rem;
  padding-left: 1.5rem;
}
.pu-button_l .pu-icon-svg.m {
  width: 24px;
  height: 24px;
}
.pu-button_l.pu-button_only-icon {
  padding: 8px;
}
.pu-button_l.pu-button_only-icon.pu-button_secondary {
  padding: 0.5rem 1.5rem;
}
.pu-button_l.pu-button_only-icon.pu-button_tertiary {
  padding: 0.25rem;
}
.pu-button_primary {
  background-color: var(--pu-btn-primary-bg);
}
.pu-button_primary:active, .pu-button_primary.pu-button_pressed {
  background-color: var(--pu-btn-primary-bg-pressed);
  color: var(--pu-btn-primary-txt-pressed);
}
.pu-button_primary:not(.pu-button_pressed):not(:active):not(:disabled):hover {
  background-color: var(--pu-btn-primary-bg-hover);
}
@media (pointer: coarse) {
  .pu-button_primary:not(.pu-button_pressed):not(:active):not(:disabled):hover {
    background-color: var(--pu-btn-primary-bg);
  }
}
.pu-button_secondary {
  background-color: var(--pu-btn-secondary-bg);
}
.pu-button_secondary:active, .pu-button_secondary.pu-button_pressed {
  background-color: var(--pu-btn-secondary-bg-pressed);
  color: var(--pu-btn-secondary-txt-pressed);
}
.pu-button_secondary:not(.pu-button_pressed):not(:active):not(:disabled):hover {
  background-color: var(--pu-btn-secondary-bg-hover);
  color: var(--pu-btn-secondary-txt-hover);
}
@media (pointer: coarse) {
  .pu-button_secondary:not(.pu-button_pressed):not(:active):not(:disabled):hover {
    background-color: var(--pu-btn-secondary-bg);
  }
}
.pu-button_tertiary {
  background-color: var(--pu-btn-tertiary-bg);
}
.pu-button_tertiary:active, .pu-button_tertiary.pu-button_pressed {
  background-color: var(--pu-btn-tertiary-bg-pressed);
  color: var(--pu-btn-tertiary-txt-pressed);
}
.pu-button_tertiary:not(.pu-button_pressed):not(:active):not(:disabled):hover {
  background-color: var(--pu-btn-tertiary-bg-hover);
  color: var(--pu-btn-tertiary-txt-hover);
}
@media (pointer: coarse) {
  .pu-button_tertiary:not(.pu-button_pressed):not(:active):not(:disabled):hover {
    background-color: var(--pu-btn-tertiary-bg);
  }
}
.pu-button_quaternary {
  align-self: center;
  padding: 0;
  background-color: transparent;
  color: var(--pu-btn-quaternary-txt);
  transition: font-size 0.2s ease-in-out;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}
.pu-button_quaternary:active, .pu-button_quaternary.pu-button_pressed {
  text-decoration: none;
  color: var(--pu-btn-quaternary-txt-pressed);
}
.pu-button_quaternary:not(.pu-button_pressed):not(:active):not(:disabled):hover {
  color: var(--pu-btn-quaternary-txt-hover);
}
@media (pointer: coarse) {
  .pu-button_quaternary:not(.pu-button_pressed):not(:active):not(:disabled):hover {
    color: var(--pu-btn-quaternary-txt);
  }
}
.pu-button_quinary {
  padding: 0;
  background-color: transparent;
  transition: font-size 0.2s ease-in-out;
  color: var(--pu-btn-quinary-txt);
}
.pu-button_quinary:active, .pu-button_quinary.pu-button_pressed {
  color: var(--pu-btn-quinary-txt-pressed);
}
.pu-button_quinary:not(.pu-button_pressed):not(:active):not(:disabled):hover {
  color: var(--pu-btn-quinary-txt-hover);
}
@media (pointer: coarse) {
  .pu-button_quinary:not(.pu-button_pressed):not(:active):not(:disabled):hover {
    color: var(--pu-btn-quinary-txt);
  }
}
.pu-button_gradient {
  position: relative;
  min-width: 6.25rem;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: transparent;
  color: var(--pu-sub-category-tab-txt);
}
.pu-button_gradient::before {
  transition: 0.2s ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  border: 1px solid transparent;
  background: var(--pu-sub-category-tab-br) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
.pu-button_gradient:active, .pu-button_gradient.pu-button_pressed {
  background: var(--pu-sub-category-tab-bg);
  color: var(--pu-sub-category-tab-txt-active);
}
.pu-button_gradient:active::before, .pu-button_gradient.pu-button_pressed::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  border: 1px solid transparent;
  background: var(--pu-sub-category-tab-br-active) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
.pu-button_gradient:not(.pu-button_pressed):not(:disabled):hover {
  color: var(--pu-sub-category-tab-txt-hover);
}
.pu-button_gradient:not(.pu-button_pressed):not(:disabled):hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  border: 1px solid transparent;
  background: var(--pu-sub-category-tab-br-hover) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
@media (pointer: coarse) {
  .pu-button_gradient:not(.pu-button_pressed):not(:active):not(:disabled):hover {
    color: var(--pu-sub-category-tab-txt);
  }
  .pu-button_gradient:not(.pu-button_pressed):not(:active):not(:disabled):hover::before {
    background: transparent;
  }
}
.pu-button:disabled, .pu-button_disabled {
  pointer-events: none;
  opacity: 0.3;
}

@media (min-width: 768px) {
  .md\:pu-button_s {
    gap: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    font-weight: 500;
    padding-top: 0.3125rem;
    padding-right: 1rem;
    padding-bottom: 0.3125rem;
    padding-left: 1rem;
  }
  .md\:pu-button_s .pu-icon-svg.m {
    width: 12px;
    height: 12px;
  }
  .md\:pu-button_m {
    gap: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;
    font-weight: 500;
    padding-top: 0.4375rem;
    padding-right: 1.5rem;
    padding-bottom: 0.4375rem;
    padding-left: 1.5rem;
  }
  .md\:pu-button_m .pu-icon-svg.m {
    width: 20px;
    height: 20px;
  }
  .md\:pu-button_m.pu-button_only-icon {
    padding: 6px;
  }
  .md\:pu-button_m.pu-button_only-icon .pu-icon-svg.m {
    width: 24px;
    height: 24px;
  }
  .md\:pu-button_l {
    gap: 0.5rem;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 400;
    font-weight: 500;
    padding-top: 0.5625rem;
    padding-right: 1.5rem;
    padding-bottom: 0.5625rem;
    padding-left: 1.5rem;
  }
  .md\:pu-button_l .pu-icon-svg.m {
    width: 24px;
    height: 24px;
  }
  .md\:pu-button_l.pu-button_only-icon {
    padding: 8px;
  }
  .md\:pu-button_l.pu-button_only-icon.pu-button_secondary {
    padding: 0.5rem 1.5rem;
  }
  .md\:pu-button_l.pu-button_only-icon.pu-button_tertiary {
    padding: 0.25rem;
  }
}
@media (min-width: 1280px) {
  .lg\:pu-button_s {
    gap: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    font-weight: 500;
    padding-top: 0.3125rem;
    padding-right: 1rem;
    padding-bottom: 0.3125rem;
    padding-left: 1rem;
  }
  .lg\:pu-button_s .pu-icon-svg.m {
    width: 12px;
    height: 12px;
  }
  .lg\:pu-button_m {
    gap: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;
    font-weight: 500;
    padding-top: 0.4375rem;
    padding-right: 1.5rem;
    padding-bottom: 0.4375rem;
    padding-left: 1.5rem;
  }
  .lg\:pu-button_m .pu-icon-svg.m {
    width: 20px;
    height: 20px;
  }
  .lg\:pu-button_m.pu-button_only-icon {
    padding: 6px;
  }
  .lg\:pu-button_m.pu-button_only-icon .pu-icon-svg.m {
    width: 24px;
    height: 24px;
  }
  .lg\:pu-button_l {
    gap: 0.5rem;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 400;
    font-weight: 500;
    padding-top: 0.5625rem;
    padding-right: 1.5rem;
    padding-bottom: 0.5625rem;
    padding-left: 1.5rem;
  }
  .lg\:pu-button_l .pu-icon-svg.m {
    width: 24px;
    height: 24px;
  }
  .lg\:pu-button_l.pu-button_only-icon {
    padding: 8px;
  }
  .lg\:pu-button_l.pu-button_only-icon.pu-button_secondary {
    padding: 0.5rem 1.5rem;
  }
  .lg\:pu-button_l.pu-button_only-icon.pu-button_tertiary {
    padding: 0.25rem;
  }
}
@media (min-width: 1366px) {
  .xl\:pu-button_s {
    gap: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    font-weight: 500;
    padding-top: 0.3125rem;
    padding-right: 1rem;
    padding-bottom: 0.3125rem;
    padding-left: 1rem;
  }
  .xl\:pu-button_s .pu-icon-svg.m {
    width: 12px;
    height: 12px;
  }
  .xl\:pu-button_m {
    gap: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;
    font-weight: 500;
    padding-top: 0.4375rem;
    padding-right: 1.5rem;
    padding-bottom: 0.4375rem;
    padding-left: 1.5rem;
  }
  .xl\:pu-button_m .pu-icon-svg.m {
    width: 20px;
    height: 20px;
  }
  .xl\:pu-button_m.pu-button_only-icon {
    padding: 6px;
  }
  .xl\:pu-button_m.pu-button_only-icon .pu-icon-svg.m {
    width: 24px;
    height: 24px;
  }
  .xl\:pu-button_l {
    gap: 0.5rem;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 400;
    font-weight: 500;
    padding-top: 0.5625rem;
    padding-right: 1.5rem;
    padding-bottom: 0.5625rem;
    padding-left: 1.5rem;
  }
  .xl\:pu-button_l .pu-icon-svg.m {
    width: 24px;
    height: 24px;
  }
  .xl\:pu-button_l.pu-button_only-icon {
    padding: 8px;
  }
  .xl\:pu-button_l.pu-button_only-icon.pu-button_secondary {
    padding: 0.5rem 1.5rem;
  }
  .xl\:pu-button_l.pu-button_only-icon.pu-button_tertiary {
    padding: 0.25rem;
  }
}
@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
.pu-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.pu-form-group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  gap: 1rem 0.75rem;
}
.pu-form-group > *:not(.pu-hard-control) {
  flex: 1 1 auto;
  width: auto;
  min-width: 80px;
}
@media (min-width: 360px) {
  .pu-form-group {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .pu-form-group_centered {
    align-items: center;
    gap: 1rem 1.5rem;
  }
}

.pu-hard-control {
  flex: 0 0 auto;
  width: auto;
  min-width: 20px;
}

.pu-form-btn {
  white-space: nowrap;
  min-width: auto !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  margin-top: 0.375rem !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-clip: content-box !important;
}

.pu-control {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.pu-control input:-webkit-autofill,
.pu-control input:-webkit-autofill:hover,
.pu-control input:-webkit-autofill:focus,
.pu-control input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px var(--pu-input-sh-inset, var(--color-bg-1)) inset !important;
  -webkit-text-fill-color: var(--pu-input-txt-filled) !important;
}
.pu-control[disabled] {
  opacity: 0.3;
}
.pu-control[disabled] .pu-control__icon {
  cursor: initial;
  pointer-events: none;
}
.pu-control__content {
  position: relative;
  display: flex;
  flex-direction: column;
}
.pu-control__input {
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  font-weight: 500;
  padding: 0.75rem 11px 0.5rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: var(--pu-input-txt-filled);
  background-color: transparent;
  margin-top: 6px;
}
.pu-control__input::placeholder {
  color: var(--pu-input-txt-placeholder);
  font-weight: 400;
}
.pu-control__input:focus {
  outline: none;
}
.pu-control__input:focus + .pu-control__label {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  color: var(--pu-input-txt-label);
  top: -1px;
  left: 0.5rem;
  padding: 0 0.25rem;
}
@media (min-width: 768px) {
  .pu-control__input:focus + .pu-control__label {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;
  }
}
.pu-control__input:focus + .pu-control__label.pu-control__label_small {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
}
.pu-control__input:focus ~ .pu-control__textarea-grab {
  background-color: var(--pu-input-br-focus);
}
.pu-control__input:focus ~ .pu-control__textarea-grab:after, .pu-control__input:focus ~ .pu-control__textarea-grab:before {
  background-color: var(--pu-input-br-focus);
}
.pu-control__input:read-only {
  pointer-events: none;
  color: var(--pu-input-txt-uneditable);
}
.pu-control__input:read-only ~ .pu-control__textarea-grab {
  background-color: var(--pu-input-br-uneditable);
}
.pu-control__input:read-only ~ .pu-control__textarea-grab:after, .pu-control__input:read-only ~ .pu-control__textarea-grab:before {
  background-color: var(--pu-input-br-uneditable);
}
.pu-control__input:read-only ~ .pu-control__label_active {
  color: var(--pu-input-txt-label-uneditable);
}
.pu-control__input_invalid ~ .pu-control__textarea-grab, .pu-control__input_invalid:focus ~ .pu-control__textarea-grab, .pu-control__input_invalid.pu-control__input:read-only ~ .pu-control__textarea-grab {
  background-color: var(--pu-cr-danger);
}
.pu-control__input_invalid ~ .pu-control__textarea-grab:after, .pu-control__input_invalid ~ .pu-control__textarea-grab:before, .pu-control__input_invalid:focus ~ .pu-control__textarea-grab:after, .pu-control__input_invalid:focus ~ .pu-control__textarea-grab:before, .pu-control__input_invalid.pu-control__input:read-only ~ .pu-control__textarea-grab:after, .pu-control__input_invalid.pu-control__input:read-only ~ .pu-control__textarea-grab:before {
  background-color: var(--pu-cr-danger);
}
.pu-control__input_invalid:focus + .pu-control__label, .pu-control__input_invalid.pu-control__input:read-only + .pu-control__label {
  color: var(--pu-cr-danger);
}
.pu-control__input_padding {
  padding-right: 42px;
}
.pu-control__input_sum {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 400;
  font-weight: 500;
  padding: 10px 35px 6px 11px;
  width: 100%;
}
.pu-control__input_sum::placeholder {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  font-weight: 400;
}
.pu-control__input_textarea {
  resize: vertical;
  min-height: 40px;
}
.pu-control__input_textarea::-webkit-resizer {
  display: none;
}
.pu-text-area_resize-disabled .pu-control__input_textarea {
  resize: none;
}
.pu-control__input_right {
  text-align: right;
  padding-right: 31px;
}
.pu-control__label {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  color: var(--pu-input-txt-placeholder);
  position: absolute;
  transition: 0.1s linear;
  left: 11px;
  top: 18px;
  pointer-events: none;
}
.pu-control__label_active {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  color: var(--pu-input-txt-label);
  top: -1px;
  left: 0.5rem;
  padding: 0 0.25rem;
}
@media (min-width: 768px) {
  .pu-control__label_active {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;
  }
}
.pu-control__label_active.pu-control__label_small {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
}
.pu-control__label_invalid:is(.pu-control__label_active) {
  color: var(--pu-cr-danger);
}
.pu-control__icon {
  position: absolute;
  right: 0.75rem;
  top: calc(50% + 5px);
  transform: translateY(-50%);
  color: var(--pu-input-icon);
}
.pu-control__icon_tooltip {
  cursor: pointer;
}
.pu-control__currency {
  position: absolute;
  top: calc(50% + 5px);
  right: 0.75rem;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  text-align: center;
  color: var(--pu-input-txt-filled);
}
.pu-control__currency:before {
  content: "";
  position: absolute;
  top: -2px;
  left: -8px;
  width: 1px;
  height: calc(100% + 4px);
  background-color: var(--pu-input-br-active);
  pointer-events: none;
}
.pu-control__currency_right:before {
  display: none;
}
.pu-control__hint {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  color: var(--pu-input-hint-txt);
  position: absolute;
  bottom: -20px;
  padding-left: 0.75rem;
}
.pu-accrual-user-form__amount .pu-control__hint {
  padding-left: 0;
}
.pu-control__hint:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  background-color: transparent;
}
.pu-accrual-user-form__amount .pu-control__hint:before {
  content: none;
}
.pu-control__hint_invalid {
  color: var(--pu-cr-danger);
}
.pu-control__hint_invalid:before {
  background-color: var(--pu-cr-danger);
}
.pu-control__hint_input {
  margin-top: 0.5rem;
  position: relative;
  bottom: 0;
}
.pu-control__hint_input:before {
  transform: none;
  top: 6px;
}
.pu-control__textarea-grab {
  position: absolute;
  bottom: 10px;
  right: 1px;
  height: 1px;
  width: 19px;
  border-radius: 2px;
  transform: matrix(-0.71, 0.7, -0.71, -0.71, 0, 0);
  background-color: var(--pu-input-br-active);
  pointer-events: none;
}
.pu-control__textarea-grab:before, .pu-control__textarea-grab:after {
  content: "";
  position: absolute;
  height: 1px;
  border-radius: 2px;
  background-color: var(--pu-input-br-active);
}
.pu-control__textarea-grab:before {
  left: 3px;
  top: -3px;
  width: 13px;
}
.pu-control__textarea-grab:after {
  left: 6px;
  top: -6px;
  width: 7px;
}
.pu-control__textarea-grab_invalid {
  background-color: var(--pu-cr-danger);
}
.pu-control__textarea-grab_invalid:after, .pu-control__textarea-grab_invalid:before {
  background-color: var(--pu-cr-danger);
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
.swiper-wrapper {
  display: flex;
  justify-content: flex-start;
}

.swiper-button-disabled,
.swiper-button-lock,
.swiper-pagination-lock {
  display: none !important;
}

.pu-swiper-centered .swiper-wrapper {
  justify-content: center;
  transform: translate3d(0px, 0, 0) !important;
}

.pu-swiper-slide-width-auto .swiper-slide {
  width: auto;
}

.pu-swiper-slide-height-auto .swiper-slide {
  height: auto;
}

.pu-swiper-gap-4 .swiper-slide:not(:last-child):has(*) {
  margin-right: calc(0.25rem - 0.25rem);
}
@media (min-width: 768px) {
  .pu-swiper-gap-4 .swiper-slide:not(:last-child):has(*) {
    margin-right: 0.25rem;
  }
}
.pu-swiper-gap-8 .swiper-slide:not(:last-child):has(*) {
  margin-right: calc(0.5rem - 0.25rem);
}
@media (min-width: 768px) {
  .pu-swiper-gap-8 .swiper-slide:not(:last-child):has(*) {
    margin-right: 0.5rem;
  }
}
.pu-swiper-gap-12 .swiper-slide:not(:last-child):has(*) {
  margin-right: calc(0.75rem - 0.25rem);
}
@media (min-width: 768px) {
  .pu-swiper-gap-12 .swiper-slide:not(:last-child):has(*) {
    margin-right: 0.75rem;
  }
}
.pu-swiper-gap-16 .swiper-slide:not(:last-child):has(*) {
  margin-right: calc(1rem - 0.25rem);
}
@media (min-width: 768px) {
  .pu-swiper-gap-16 .swiper-slide:not(:last-child):has(*) {
    margin-right: 1rem;
  }
}
.pu-swiper-gap-20 .swiper-slide:not(:last-child):has(*) {
  margin-right: calc(1.25rem - 0.25rem);
}
@media (min-width: 768px) {
  .pu-swiper-gap-20 .swiper-slide:not(:last-child):has(*) {
    margin-right: 1.25rem;
  }
}
.pu-swiper-gap-24 .swiper-slide:not(:last-child):has(*) {
  margin-right: calc(1.5rem - 0.25rem);
}
@media (min-width: 768px) {
  .pu-swiper-gap-24 .swiper-slide:not(:last-child):has(*) {
    margin-right: 1.5rem;
  }
}
.pu-swiper-gap-28 .swiper-slide:not(:last-child):has(*) {
  margin-right: calc(1.75rem - 0.25rem);
}
@media (min-width: 768px) {
  .pu-swiper-gap-28 .swiper-slide:not(:last-child):has(*) {
    margin-right: 1.75rem;
  }
}
.pu-swiper-gap-32 .swiper-slide:not(:last-child):has(*) {
  margin-right: calc(2rem - 0.25rem);
}
@media (min-width: 768px) {
  .pu-swiper-gap-32 .swiper-slide:not(:last-child):has(*) {
    margin-right: 2rem;
  }
}
.swiper-pagination {
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  inset: auto 0 -0.75rem 0;
  cursor: pointer;
}

.swiper-pagination-bullet {
  width: 0.375rem;
  height: 0.375rem;
  background: var(--pu-slider-pagination-cr);
  opacity: 0.4;
  border-radius: 50%;
}

.swiper-pagination-bullet-active {
  opacity: initial;
}

div.cdk-overlay-container > div.cdk-global-overlay-wrapper > div[id*=cdk-overlay-].cdk-overlay-pane {
  display: flex !important;
}

.cdk-overlay-backdrop,
div.cdk-overlay-backdrop.cdk-overlay-backdrop-showing,
.cdk-global-overlay-wrapper {
  opacity: 0;
  transition: opacity 0.3s linear;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing:not(:has(~ .pu-overlay-backdrop)) {
  opacity: 1;
}

.cdk-global-overlay-wrapper:not(:has(~ * > .pu-overlay-panel)) {
  opacity: 1;
}

.cdk-overlay-backdrop.pu-overlay-backdrop {
  background: var(--pu-overlay-bg-darken);
}

.cdk-overlay-backdrop.pu-overlay-backdrop_transparent {
  background: var(--pu-overlay-bg-transparent);
}

.cdk-overlay-backdrop.pu-interview-viewer-backdrop {
  background: var(--pu-overlay-bg-darken, var(--blur-bg-color));
}

.cdk-overlay-backdrop.pu-fullscreen-image-viewer-backdrop {
  background: var(--pu-fullscreen-image-viewer-overlay-bg);
}

.cdk-overlay-backdrop.pt-tour-guide-backdrop {
  background: var(--pu-overlay-bg-darken, var(--blur-bg-color));
  backdrop-filter: blur(8px);
}

.pu-overlay-panel {
  display: flex;
  flex-direction: column;
  cursor: auto;
  height: 100%;
  justify-content: center;
}
.pu-overlay-panel_full-width {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pu-overlay-panel_full-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pu-overlay-panel_navbar-menu, .pu-overlay-panel_pwa-setup {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
:root {
  --pu-uploader-w: 8.125rem;
  --pu-uploader-h: 5.75rem;
  --pu-uploader-rd: 0.25rem;
  --pu-uploader-spinner-w: 3rem;
}

.pu-uploader-info,
.pu-uploader-file,
.pu-uploader-image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0.5rem;
  width: var(--pu-uploader-w);
  height: var(--pu-uploader-h);
  border-radius: var(--pu-uploader-rd);
  cursor: pointer;
  background-color: var(--pu-uploader-file-bg);
}
.pu-uploader-info:hover,
.pu-uploader-file:hover,
.pu-uploader-image:hover {
  background-color: var(--pu-uploader-file-bg-hover);
}
.pu-uploader-info__remove,
.pu-uploader-file__remove,
.pu-uploader-image__remove {
  visibility: hidden;
}

.pu-uploader-info {
  flex-direction: column;
  gap: 0.5rem;
}
.pu-uploader-info__message {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  color: var(--pu-uploader-file-txt);
}
.pu-uploader-info__sub-message {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  color: var(--pu-uploader-file-sub-txt);
}
.pu-uploader-info:hover .pu-uploader-info__remove {
  visibility: visible;
}

.pu-uploader-file {
  width: auto;
  min-width: var(--pu-uploader-w);
  --pu-uploader-h: 3rem;
  gap: 0.5rem;
}
.pu-uploader-file__name {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  color: var(--pu-uploader-file-txt);
}
.pu-uploader-file:hover .pu-uploader-file__remove {
  visibility: visible;
}

.pu-uploader-image__img {
  max-height: 100%;
}
.pu-uploader-image__remove {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.pu-uploader-image:hover .pu-uploader-image__img {
  filter: brightness(0.3);
}
.pu-uploader-image:hover .pu-uploader-image__remove {
  visibility: visible;
  color: var(--pu-uploader-file-icon-remove);
}

.pu-highlighted-text {
  color: var(--pu-txt-highlight);
}

/*
 * froala_editor v4 (https://www.froala.com/wysiwyg-editor)
 */
.fr-box ul {
  margin-left: 20px;
  list-style-type: disc;
}
.fr-box ol {
  margin-left: 20px;
  list-style-type: decimal;
}
.fr-box b, .fr-box strong {
  font-weight: bold;
}
.fr-box i, .fr-box em {
  font-style: italic;
}
.fr-box.fr-basic {
  border-radius: 5px;
}
.fr-box.fr-basic .fr-toolbar {
  background-color: transparent;
  border-radius: 5px 5px 0 0;
  border-bottom: 0;
}
.fr-box.fr-basic .fr-wrapper {
  background-color: transparent;
  border: 1px solid #cccccc;
}
.fr-box.fr-basic .fr-second-toolbar {
  border-top: 0;
  background-color: transparent;
  border-radius: 0 0 5px 5px;
}
.fr-box.fr-basic.fr-top .fr-wrapper {
  border-top: 1px solid #cccccc;
}
.fr-box.fr-fullscreen .fr-toolbar {
  border-radius: 0;
  border-bottom: 0;
  background-color: var(--pu-input-list-bg);
}
.fr-box.fr-fullscreen .fr-wrapper {
  background-color: var(--pu-input-list-bg);
  border: 1px solid #cccccc;
}
.fr-box.fr-fullscreen .fr-second-toolbar {
  border-radius: 0;
  border-top: 0;
  background-color: var(--pu-input-list-bg);
}

:root.pu-dark-theme .fr-box.fr-basic .fr-element {
  color: var(--pu-input-txt-filled);
}
:root.pu-dark-theme .fr-box a.fr-floating-btn {
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background: #353535;
  color: #0098f7;
}
:root.pu-dark-theme .fr-box a.fr-floating-btn svg {
  fill: #0098f7;
}
:root.pu-dark-theme .fr-box a.fr-floating-btn:hover {
  background: #424242;
}
:root.pu-dark-theme .fr-box a.fr-floating-btn:hover svg {
  fill: #0098f7;
}
:root.pu-dark-theme .fr-wrapper .fr-placeholder {
  color: #aaa;
}
:root.pu-dark-theme .fr-wrapper ::-moz-selection {
  background: rgba(181, 214, 253, 0.8);
  color: #000;
}
:root.pu-dark-theme .fr-wrapper ::selection {
  background: rgba(181, 214, 253, 0.8);
  color: #000;
}
:root.pu-dark-theme .fr-box.fr-basic .fr-wrapper {
  background: transparent;
  border: 1px solid var(--pu-input-br-active);
  border-bottom-color: var(--pu-input-br-active);
}
@media (min-width: 992px) {
  :root.pu-dark-theme .fr-box.fr-document .fr-wrapper {
    background: #efefef;
  }
  :root.pu-dark-theme .fr-box.fr-document .fr-wrapper .fr-element {
    background: #fff;
  }
  :root.pu-dark-theme .fr-box.fr-document .fr-wrapper .fr-element hr {
    background: #efefef;
  }
}
:root.pu-dark-theme .fr-box .fr-counter {
  color: #999999;
}
:root.pu-dark-theme textarea.fr-code {
  background: #fff;
  color: #000;
}
:root.pu-dark-theme .fr-box.fr-inline .fr-command.fr-btn.html-switch {
  background: #fff;
  color: #fff;
}
:root.pu-dark-theme .fr-box.fr-inline .fr-command.fr-btn.html-switch.fr-desktop:hover {
  background: #424242;
}
:root.pu-dark-theme .fr-popup .fr-color-set > span > i path, :root.pu-dark-theme .fr-popup .fr-color-set > span > svg path {
  fill: #fff;
}
:root.pu-dark-theme .fr-popup .fr-color-set > span .fr-selected-color {
  color: #fff;
}
:root.pu-dark-theme .fr-popup .fr-color-set > span:hover, :root.pu-dark-theme .fr-popup .fr-color-set > span:focus {
  outline: 1px solid #fff;
}
:root.pu-dark-theme .fr-drag-helper {
  background: #0098f7;
}
:root.pu-dark-theme .fr-popup .fr-file-upload-layer {
  border: dashed 2px gray;
}
:root.pu-dark-theme .fr-popup .fr-file-upload-layer:hover {
  background: #424242;
}
:root.pu-dark-theme .fr-popup .fr-file-upload-layer.fr-drop {
  background: #424242;
  border-color: #0098f7;
}
:root.pu-dark-theme .fr-popup .fr-file-progress-bar-layer > div.fr-loader {
  background: #b3e0fd;
}
:root.pu-dark-theme .fr-popup .fr-file-progress-bar-layer > div.fr-loader span {
  background: #0098f7;
}
:root.pu-dark-theme .fr-image-resizer {
  border: solid 1px #0098f7;
}
:root.pu-dark-theme .fr-image-resizer .fr-handler {
  background: #0098f7;
  border: solid 1px #fff;
}
:root.pu-dark-theme .fr-popup .fr-files-upload-layer {
  border: dashed 2px gray;
}
:root.pu-dark-theme .fr-popup .fr-files-upload-layer:hover {
  background: #424242;
}
:root.pu-dark-theme .fr-popup .fr-files-upload-layer.fr-drop {
  background: #424242;
  border-color: #0098f7;
}
:root.pu-dark-theme .fr-popup .fr-files-progress-bar-layer > div.fr-loader {
  background: #b3e0fd;
}
:root.pu-dark-theme .fr-popup .fr-files-progress-bar-layer > div.fr-loader span {
  background: #0098f7;
  -webkit-transition: width 0.2s ease 0s;
  -moz-transition: width 0.2s ease 0s;
  -ms-transition: width 0.2s ease 0s;
  -o-transition: width 0.2s ease 0s;
}
:root.pu-dark-theme .fr-progress-bar {
  background-color: #4caf50;
}
:root.pu-dark-theme .fr-files-checkbox input:not(:checked) + span {
  border: solid 2px #999999;
}
:root.pu-dark-theme .fr-files-checkbox input:not(:checked):focus + span, :root.pu-dark-theme .fr-files-checkbox input:not(:checked):hover + span {
  border-color: #515151;
}
:root.pu-dark-theme .fr-files-checkbox input:checked + span {
  background: #0098f7;
  border: solid 2px #0098f7;
}
:root.pu-dark-theme .fr-files-checkbox input:checked:active + span {
  background-color: #ecf5ff;
}
:root.pu-dark-theme .fr-checkbox-disabled input:not(:checked) + span {
  border: solid 2px #999999;
}
:root.pu-dark-theme .fr-checkbox-disabled input:not(:checked):active + span {
  background-color: #353535;
}
:root.pu-dark-theme .fr-checkbox-disabled input:checked + span {
  background: #0098f7;
  border: solid 2px #0098f7;
}
:root.pu-dark-theme .fr-checkbox-disabled input:checked:active + span {
  background-color: #ecf5ff;
}
:root.pu-dark-theme #myprogressBar {
  background-color: #4caf50;
}
:root.pu-dark-theme .fr-hovered-over-file {
  background-color: #f0f0f0;
}
:root.pu-dark-theme .fr-box.fr-fullscreen .fr-wrapper {
  background-color: var(--pu-input-list-bg);
}
:root.pu-dark-theme .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody tr {
  border-bottom: solid 1px #595959;
}
:root.pu-dark-theme .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody td:first-child {
  color: white;
}
:root.pu-dark-theme .fr-image-resizer {
  border: solid 1px #0098f7;
}
:root.pu-dark-theme .fr-image-resizer .fr-handler {
  background: #0098f7;
  border: solid 1px #fff;
}
:root.pu-dark-theme .fr-popup .fr-image-upload-layer {
  border: dashed 2px gray;
}
:root.pu-dark-theme .fr-popup .fr-image-upload-layer:hover {
  background: #424242;
}
:root.pu-dark-theme .fr-popup .fr-image-upload-layer.fr-drop {
  background: #424242;
  border-color: #0098f7;
}
:root.pu-dark-theme .fr-popup .fr-image-progress-bar-layer > div.fr-loader {
  background: #b3e0fd;
}
:root.pu-dark-theme .fr-popup .fr-image-progress-bar-layer > div.fr-loader span {
  background: #0098f7;
}
:root.pu-dark-theme .fr-modal .fr-modal-head .fr-modal-tags a {
  background-color: #353535;
}
:root.pu-dark-theme .fr-modal .fr-modal-head .fr-modal-tags a:focus {
  background-color: #424242;
}
:root.pu-dark-theme .fr-modal .fr-modal-head .fr-modal-tags a:hover {
  background-color: #424242;
}
:root.pu-dark-theme .fr-modal .fr-modal-head .fr-modal-tags a:active {
  background-color: #4f4f4f;
}
:root.pu-dark-theme .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag {
  background-color: #4f4f4f;
  color: #0098f7;
}
:root.pu-dark-theme .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:focus {
  background-color: #424242;
}
:root.pu-dark-theme .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:hover {
  background-color: #424242;
}
:root.pu-dark-theme .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:active {
  background-color: #4f4f4f;
}
:root.pu-dark-theme .fr-show-tags .fr-modal-more svg path {
  fill: #0098f7;
}
:root.pu-dark-theme div.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::after {
  background: #000;
}
:root.pu-dark-theme div.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::before {
  color: #fff;
}
:root.pu-dark-theme div.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty {
  background: #aaa;
}
:root.pu-dark-theme div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img {
  background: #b8312f;
  fill: #fff;
}
:root.pu-dark-theme div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
  background: #353535;
  fill: #0098f7;
}
:root.pu-dark-theme .fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img:hover {
  background: #bf4644;
  color: #fff;
}
:root.pu-dark-theme .fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img:hover {
  background: #424242;
}
:root.pu-dark-theme .fr-line-breaker {
  border-top: 1px solid #0098f7;
}
:root.pu-dark-theme .fr-qi-helper a.fr-btn.fr-floating-btn {
  color: #fff;
  background: #353535;
}
:root.pu-dark-theme .fr-qi-helper a.fr-btn.fr-floating-btn svg {
  fill: #fff;
}
:root.pu-dark-theme .fr-element table td.fr-selected-cell, :root.pu-dark-theme .fr-element table th.fr-selected-cell {
  border: 1px double #0098f7;
}
:root.pu-dark-theme .fr-table-resizer div {
  border-right: 1px solid #0098f7;
}
:root.pu-dark-theme .fr-popup .fr-table-size .fr-select-table-size > span > span {
  border: 1px solid #ddd;
}
:root.pu-dark-theme .fr-popup .fr-table-size .fr-select-table-size > span.hover > span {
  background: rgba(0, 152, 247, 0.3);
  border: solid 1px #0098f7;
}
:root.pu-dark-theme .fr-box .fr-video-resizer {
  border: solid 1px #0098f7;
}
:root.pu-dark-theme .fr-box .fr-video-resizer .fr-handler {
  background: #0098f7;
  border: solid 1px #fff;
}
:root.pu-dark-theme .fr-popup .fr-video-upload-layer {
  border: dashed 2px gray;
}
:root.pu-dark-theme .fr-popup .fr-video-upload-layer:hover {
  background: #424242;
}
:root.pu-dark-theme .fr-popup .fr-video-upload-layer.fr-drop {
  background: #424242;
  border-color: #0098f7;
}
:root.pu-dark-theme .fr-popup .fr-video-progress-bar-layer > div.fr-loader {
  background: #b3e0fd;
}
:root.pu-dark-theme .fr-popup .fr-video-progress-bar-layer > div.fr-loader span {
  background: #0098f7;
}
:root.pu-dark-theme .fr-view img.fr-shadow, :root.pu-dark-theme .fr-view .fr-img-caption.fr-shadow img {
  -webkit-box-shadow: 10px 10px 5px 0px #cccccc;
  -moz-box-shadow: 10px 10px 5px 0px #cccccc;
  box-shadow: 10px 10px 5px 0px #cccccc;
}
:root.pu-dark-theme .fr-view span[style~="color:"] a {
  color: inherit;
}
:root.pu-dark-theme .fr-view table.fr-alternate-rows tbody tr:nth-child(2n) {
  background: lightgray;
}
:root.pu-dark-theme .fr-view table td, :root.pu-dark-theme .fr-view table th {
  border: 1px solid #ddd;
}
:root.pu-dark-theme .fr-view table th {
  background: #ececec;
}
:root.pu-dark-theme .fr-view table tfoot td {
  background: #ececec;
}
:root.pu-dark-theme .fr-view[dir=rtl] blockquote {
  border-right: solid 2px #5e35b1;
}
:root.pu-dark-theme .fr-view[dir=rtl] blockquote blockquote {
  border-color: #00bcd4;
}
:root.pu-dark-theme .fr-view[dir=rtl] blockquote blockquote blockquote {
  border-color: #43a047;
}
:root.pu-dark-theme .fr-view blockquote {
  border-left: solid 2px #5e35b1;
  color: #5e35b1;
}
:root.pu-dark-theme .fr-view blockquote blockquote {
  border-color: #00bcd4;
  color: #00bcd4;
}
:root.pu-dark-theme .fr-view blockquote blockquote blockquote {
  border-color: #43a047;
  color: #43a047;
}
:root.pu-dark-theme .fr-view .fr-class-highlighted {
  background-color: #ffff00;
}
:root.pu-dark-theme .fr-view .fr-class-code {
  background: #353535;
}
:root.pu-dark-theme .fr-box .fr-embedly-resizer {
  border: solid 1px #0098f7;
}
:root.pu-dark-theme .tui-editor-cancel-btn {
  background-color: #fff;
  border: 1px solid #aaa;
}
:root.pu-dark-theme .tui-editor-save-btn {
  color: #fff;
}
:root.pu-dark-theme .sc-cm-holder > .sc-cm {
  border-top: 5px solid #222222 !important;
}
:root.pu-dark-theme .sc-cm__item_dropdown:hover > a, :root.pu-dark-theme .sc-cm a:hover {
  background-color: #424242 !important;
}
:root.pu-dark-theme .sc-cm__item_active > a, :root.pu-dark-theme .sc-cm__item_active > a:hover, :root.pu-dark-theme .sc-cm a:active, :root.pu-dark-theme .sc-cm a:focus {
  background-color: #424242 !important;
}
:root.pu-dark-theme .sc-cm-holder > .sc-cm:before {
  background-color: #424242 !important;
}
:root.pu-dark-theme .fr-tooltip {
  background: #222222;
  color: #fff;
}
:root.pu-dark-theme .fr-toolbar .fr-command.fr-btn, :root.pu-dark-theme .fr-popup .fr-command.fr-btn, :root.pu-dark-theme .fr-modal .fr-command.fr-btn {
  color: #fff;
  border: 0;
}
:root.pu-dark-theme .fr-toolbar .fr-command.fr-btn::-moz-focus-inner, :root.pu-dark-theme .fr-popup .fr-command.fr-btn::-moz-focus-inner, :root.pu-dark-theme .fr-modal .fr-command.fr-btn::-moz-focus-inner {
  border: 0;
}
:root.pu-dark-theme .fr-toolbar .fr-command.fr-btn svg path, :root.pu-dark-theme .fr-popup .fr-command.fr-btn svg path, :root.pu-dark-theme .fr-modal .fr-command.fr-btn svg path {
  fill: #fff;
}
:root.pu-dark-theme .fr-toolbar .fr-command.fr-btn span.fr-sr-only, :root.pu-dark-theme .fr-popup .fr-command.fr-btn span.fr-sr-only, :root.pu-dark-theme .fr-modal .fr-command.fr-btn span.fr-sr-only {
  border: 0;
}
:root.pu-dark-theme .fr-toolbar .fr-command.fr-btn.fr-btn-active-popup, :root.pu-dark-theme .fr-popup .fr-command.fr-btn.fr-btn-active-popup, :root.pu-dark-theme .fr-modal .fr-command.fr-btn.fr-btn-active-popup {
  background: #4f4f4f;
}
:root.pu-dark-theme .fr-toolbar .fr-command.fr-btn.fr-dropdown:after, :root.pu-dark-theme .fr-popup .fr-command.fr-btn.fr-dropdown:after, :root.pu-dark-theme .fr-modal .fr-command.fr-btn.fr-dropdown:after {
  border-top: 4px solid #fff;
}
:root.pu-dark-theme .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active, :root.pu-dark-theme .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active, :root.pu-dark-theme .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active {
  fill: #fff;
  background: #4f4f4f;
}
:root.pu-dark-theme .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover, :root.pu-dark-theme .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover, :root.pu-dark-theme .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:hover {
  background: #424242;
  fill: #fff;
}
:root.pu-dark-theme .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover::after, :root.pu-dark-theme .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover::after, :root.pu-dark-theme .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:hover::after {
  border-top-color: #fff;
}
:root.pu-dark-theme .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:after, :root.pu-dark-theme .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:after, :root.pu-dark-theme .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:after {
  border-top: 0;
  border-bottom: 4px solid #fff;
}
:root.pu-dark-theme .fr-toolbar .fr-command.fr-btn.fr-disabled, :root.pu-dark-theme .fr-popup .fr-command.fr-btn.fr-disabled, :root.pu-dark-theme .fr-modal .fr-command.fr-btn.fr-disabled {
  color: gray;
}
:root.pu-dark-theme .fr-toolbar .fr-command.fr-btn.fr-disabled::after, :root.pu-dark-theme .fr-popup .fr-command.fr-btn.fr-disabled::after, :root.pu-dark-theme .fr-modal .fr-command.fr-btn.fr-disabled::after {
  border-top-color: gray;
}
:root.pu-dark-theme .fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active, :root.pu-dark-theme .fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab, :root.pu-dark-theme .fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active, :root.pu-dark-theme .fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab, :root.pu-dark-theme .fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active, :root.pu-dark-theme .fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab {
  background: #353535;
}
:root.pu-dark-theme .fr-toolbar .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, :root.pu-dark-theme .fr-toolbar .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, :root.pu-dark-theme .fr-toolbar .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, :root.pu-dark-theme .fr-popup .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, :root.pu-dark-theme .fr-popup .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, :root.pu-dark-theme .fr-popup .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, :root.pu-dark-theme .fr-modal .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, :root.pu-dark-theme .fr-modal .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, :root.pu-dark-theme .fr-modal .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path {
  fill: #0098f7;
}
:root.pu-dark-theme .fr-toolbar.fr-disabled .fr-btn, :root.pu-dark-theme .fr-toolbar.fr-disabled .fr-btn.fr-active, :root.pu-dark-theme .fr-popup.fr-disabled .fr-btn, :root.pu-dark-theme .fr-popup.fr-disabled .fr-btn.fr-active, :root.pu-dark-theme .fr-modal.fr-disabled .fr-btn, :root.pu-dark-theme .fr-modal.fr-disabled .fr-btn.fr-active {
  color: gray;
}
:root.pu-dark-theme .fr-toolbar.fr-disabled .fr-btn.fr-dropdown::after, :root.pu-dark-theme .fr-toolbar.fr-disabled .fr-btn.fr-active.fr-dropdown::after, :root.pu-dark-theme .fr-popup.fr-disabled .fr-btn.fr-dropdown::after, :root.pu-dark-theme .fr-popup.fr-disabled .fr-btn.fr-active.fr-dropdown::after, :root.pu-dark-theme .fr-modal.fr-disabled .fr-btn.fr-dropdown::after, :root.pu-dark-theme .fr-modal.fr-disabled .fr-btn.fr-active.fr-dropdown::after {
  border-top-color: gray;
}
:root.pu-dark-theme .fr-desktop .fr-command:hover, :root.pu-dark-theme .fr-desktop .fr-command:focus, :root.pu-dark-theme .fr-desktop .fr-command.fr-btn-hover, :root.pu-dark-theme .fr-desktop .fr-command.fr-expanded {
  outline: 0;
  color: #fff;
}
:root.pu-dark-theme .fr-desktop .fr-command:hover:not(.fr-table-cell), :root.pu-dark-theme .fr-desktop .fr-command:focus:not(.fr-table-cell), :root.pu-dark-theme .fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell), :root.pu-dark-theme .fr-desktop .fr-command.fr-expanded:not(.fr-table-cell) {
  background: #424242;
}
:root.pu-dark-theme .fr-desktop .fr-command:hover::after, :root.pu-dark-theme .fr-desktop .fr-command:focus::after, :root.pu-dark-theme .fr-desktop .fr-command.fr-btn-hover::after, :root.pu-dark-theme .fr-desktop .fr-command.fr-expanded::after {
  border-top-color: #fff;
}
:root.pu-dark-theme .fr-desktop .fr-command.fr-selected:not(.fr-table-cell), :root.pu-dark-theme .fr-desktop .fr-command:active {
  color: #fff;
  background: #4f4f4f;
}
:root.pu-dark-theme .fr-desktop .fr-command.fr-active:hover, :root.pu-dark-theme .fr-desktop .fr-command.fr-active:focus, :root.pu-dark-theme .fr-desktop .fr-command.fr-active.fr-btn-hover, :root.pu-dark-theme .fr-desktop .fr-command.fr-active.fr-expanded {
  background: #424242;
}
:root.pu-dark-theme .fr-desktop .fr-command.fr-active:active {
  background: #4f4f4f;
}
:root.pu-dark-theme .fr-toolbar.fr-mobile .fr-command.fr-blink, :root.pu-dark-theme .fr-popup.fr-mobile .fr-command.fr-blink {
  background: #4f4f4f;
}
:root.pu-dark-theme .fr-command.fr-btn.fr-options.fr-btn-hover, :root.pu-dark-theme .fr-command.fr-btn.fr-options:hover, :root.pu-dark-theme .fr-command.fr-btn.fr-options:focus {
  border-left: solid 1px #fafafa;
}
:root.pu-dark-theme .fr-command.fr-btn + .fr-dropdown-menu {
  background: #353535;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
:root.pu-dark-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a {
  color: inherit;
}
:root.pu-dark-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a svg path {
  fill: #fff;
}
:root.pu-dark-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
  background: #4f4f4f;
}
:root.pu-dark-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-disabled {
  color: gray;
}
:root.pu-dark-theme .fr-command.fr-btn.fr-active + .fr-dropdown-menu {
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
:root.pu-dark-theme .fr-bottom > .fr-command.fr-btn + .fr-dropdown-menu {
  border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
:root.pu-dark-theme .fr-modal {
  color: #fff;
}
:root.pu-dark-theme .fr-modal .fr-modal-wrapper {
  background: #353535;
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border: 0;
}
:root.pu-dark-theme .fr-modal .fr-modal-wrapper .fr-modal-head {
  background: #353535;
  border-bottom: solid #efefef 1px;
}
:root.pu-dark-theme .fr-modal .fr-modal-wrapper div.fr-modal-body:focus {
  outline: 0;
}
:root.pu-dark-theme .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command {
  color: #0098f7;
}
:root.pu-dark-theme .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:hover, :root.pu-dark-theme .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:focus {
  background: #424242;
  color: #0098f7;
}
:root.pu-dark-theme .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:active {
  background: #4f4f4f;
  color: #0098f7;
}
:root.pu-dark-theme .fr-modal .fr-modal-wrapper div.fr-modal-body button::-moz-focus-inner {
  border: 0;
}
:root.pu-dark-theme .fr-desktop .fr-modal-wrapper .fr-modal-head i:hover {
  background: #424242;
}
:root.pu-dark-theme .fr-overlay {
  background: #000;
}
:root.pu-dark-theme .fr-popup {
  color: #fff;
  background: #353535;
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}
:root.pu-dark-theme .fr-popup .fr-input-focus {
  background: #363636;
}
:root.pu-dark-theme .fr-popup.fr-above {
  border-top: 0;
}
:root.pu-dark-theme .fr-popup .fr-input-line input[type=text], :root.pu-dark-theme .fr-popup .fr-input-line input[type=number], :root.pu-dark-theme .fr-popup .fr-input-line textarea {
  border: solid 1px #999999;
  color: #fff;
  background: #353535;
}
:root.pu-dark-theme .fr-popup .fr-input-line input[type=text]:hover, :root.pu-dark-theme .fr-popup .fr-input-line input[type=number]:hover, :root.pu-dark-theme .fr-popup .fr-input-line textarea:hover {
  border: solid 1px #515151;
}
:root.pu-dark-theme .fr-popup .fr-input-line input[type=text]:focus, :root.pu-dark-theme .fr-popup .fr-input-line input[type=number]:focus, :root.pu-dark-theme .fr-popup .fr-input-line textarea:focus {
  border: solid 2px #0098f7;
}
:root.pu-dark-theme .fr-popup .fr-input-line input + label, :root.pu-dark-theme .fr-popup .fr-input-line textarea + label {
  background: #353535;
}
:root.pu-dark-theme .fr-popup .fr-input-line input.fr-not-empty + label, :root.pu-dark-theme .fr-popup .fr-input-line textarea.fr-not-empty + label {
  color: gray;
}
:root.pu-dark-theme .fr-popup .fr-buttons.fr-tabs {
  background-color: #686868;
}
:root.pu-dark-theme .fr-popup .fr-action-buttons button.fr-command {
  color: #0098f7;
}
:root.pu-dark-theme .fr-popup .fr-action-buttons button.fr-command:hover, :root.pu-dark-theme .fr-popup .fr-action-buttons button.fr-command:focus {
  background: #424242;
  color: #0098f7;
}
:root.pu-dark-theme .fr-popup .fr-action-buttons button.fr-command:active {
  background: #4f4f4f;
  color: #0098f7;
}
:root.pu-dark-theme .fr-popup .fr-checkbox input:not(:checked) + span {
  border: solid 2px #999999;
}
:root.pu-dark-theme .fr-popup .fr-checkbox input:not(:checked):active + span {
  background-color: #353535;
}
:root.pu-dark-theme .fr-popup .fr-checkbox input:not(:checked):focus + span, :root.pu-dark-theme .fr-popup .fr-checkbox input:not(:checked):hover + span {
  border-color: #515151;
}
:root.pu-dark-theme .fr-popup .fr-checkbox input:checked + span {
  background: #0098f7;
  border: solid 2px #0098f7;
}
:root.pu-dark-theme .fr-popup .fr-checkbox input:checked:active + span {
  background-color: #ecf5ff;
}
:root.pu-dark-theme .fr-toolbar {
  color: var(--pu-input-txt-filled);
  background: transparent;
  border: 1px solid var(--pu-input-br-active);
}
:root.pu-dark-theme .fr-box.fr-fullscreen .fr-toolbar {
  background-color: var(--pu-input-list-bg);
}
:root.pu-dark-theme .fr-toolbar .fr-newline {
  background: #353535;
}
:root.pu-dark-theme .fr-toolbar .fr-more-toolbar {
  background-color: #686868;
}
:root.pu-dark-theme .fr-toolbar .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active) {
  background: #686868;
}
:root.pu-dark-theme .fr-toolbar.fr-inline {
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border: 0;
}
:root.pu-dark-theme .fr-toolbar.fr-inline.fr-above {
  border-top: 0;
}
:root.pu-dark-theme .fr-toolbar.fr-top {
  border-bottom: 0;
}
:root.pu-dark-theme .fr-toolbar.fr-bottom {
  border-top: 0;
}
:root.pu-dark-theme .fr-toolbar.fr-bottom.fr-toolbar-open .fr-newline {
  background: #fff;
}
:root.pu-dark-theme .fr-separator {
  background: #595959;
}
:root.pu-dark-theme .fr-second-toolbar {
  color: var(--pu-input-txt-filled);
  background: transparent;
  border: 1px solid var(--pu-input-br-active);
  border-top: 0;
}
:root.pu-dark-theme .fr-box.fr-fullscreen .fr-second-toolbar {
  background-color: var(--pu-input-list-bg);
}
:root.pu-dark-theme #fr-logo > span {
  color: #b1b2b7;
}
:root.pu-dark-theme #fr-logo:hover > span, :root.pu-dark-theme #fr-logo:focus > span {
  color: #0098f7;
}
:root.pu-dark-theme #fr-logo:hover > svg .fr-logo, :root.pu-dark-theme #fr-logo:focus > svg .fr-logo {
  fill: #0098f7;
}

.fr-view a {
  color: var(--pu-fr-link-txt, var(--color-brand-secondary)) !important;
}
.fr-view strong, .fr-view b {
  font-weight: bold;
}
.fr-view u {
  text-decoration: underline;
}
.fr-view em {
  font-style: italic;
}
.fr-view ol, .fr-view ul {
  list-style: auto;
  margin-left: 20px;
}
