@import '@pinup-teams/common/styles/utilities/typography';

@import 'common/pu';
@import 'common/fonts';
@import 'common/container';
@import 'common/scroll';
@import 'common/scroll-shadow';
@import 'common/breadcrumbs';
@import 'common/back-btn';
@import 'common/chart';
@import 'common/header-content';
@import 'common/admin/top-bar';
@import '../components/nav-item/nav-item';

html, body {
  height: 100%;
}

html {
  font-size: $base-font-size;
  scroll-behavior: smooth;
}

body, .pt-mfe-shell {
  font-family: var(--ff-roboto);

  &.pu-touch {
    cursor: pointer;
  }
}

[hidden] {
  display: none !important;
}

.pu-root {
  display: block;
  width: 100%;
  height: 100%;

  // Preventing layout shift
  // @include breakpoint(lg) {
  //   overflow-y: scroll;

  //   @supports (scrollbar-gutter: stable) {
  //     overflow: auto;
  //     scrollbar-gutter: stable;
  //   }
  // }
}
