// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@include mat.core();

$app-theme-dark: mat.define-theme((
  color: (
    theme-type: dark,
    primary: mat.$violet-palette
  )
));

html {
  @include mat.core-theme($app-theme-dark);
  @include mat.slide-toggle-theme($app-theme-dark);
}

$app-theme-light: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$rose-palette
  )
));

@media (prefers-color-scheme: light) {
  html {
    @include mat.core-color($app-theme-light);
    @include mat.slide-toggle-color($app-theme-light);
  }
}
