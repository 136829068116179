@import '@pinup-teams/common/styles/utilities';

.mat-calendar {
  width: 100%;
  min-width: 250px;
  max-width: 340px;
  padding: $sp-16;
  border: 1px solid var(--background-color-2);
  box-shadow: var(--shadow-calendar-dropdown);
  background: var(--background-color-1);
  display: block;

  .mat-calendar-header {
    padding: 0 $sp-12;

    .mat-calendar-spacer {
      display: none;
    }

    .mat-calendar-previous-button {
      order: -1;
    }

    .mat-calendar-next-button,
    .mat-calendar-previous-button {
      background-color: none;
      color: var(--text-icon-color-5);

      &.mat-button-disabled {
        opacity: .3;
      }
    }

    .mat-calendar-period-button {
      @include font-medium-16;
      @include button-reset(false);

      background-color: none;
      pointer-events: none;
      flex: 1;
      color: var(--text-icon-color-5);

      .mat-calendar-arrow {
        display: none;
      }
    }
  }

  .mat-calendar-controls {
    margin-top: 0;
    margin-bottom: $sp-4;
  }

  .mat-calendar-content {
    padding: 0;
  }

  .mat-calendar-table-header {
    @include font-medium-10;

    color: var(--text-icon-color-6);

    th {
      padding-bottom: $sp-16;
    }

    .mat-calendar-table-header-divider {
      display: none;
    }
  }

  .mat-calendar-body {
    tr[aria-hidden] {
      display: none;
    }

    .mat-calendar-body-label {
      opacity: 0;
    }
  }

  .mat-calendar-body-cell-content {
    @include font-regular-16;

    border: none;
    color: var(--text-icon-color-3);

    &:before {
      display: none;
    }
  }

  .mat-calendar-body-disabled {
    .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      color: var(--text-icon-color-6);
    }
  }

  .mat-calendar-body-range-start:not(.mat-calendar-body-in-comparison-range),
  .mat-calendar-body-range-end:not(.mat-calendar-body-in-comparison-range),
  .mat-calendar-body-in-range {
    &::before {
      border-radius: 50%;
      background-color: var(--color-additionals-danger);
    }

    &::before,
    &::after {
      top: 6%;
      left: 9%;
      height: 84%;
      width: 84%;
    }

    .mat-calendar-body-cell-content {
      color: var(--color-datepicker-date-active-text);
    }
  }

  .mat-calendar-body-selected {
    background-color: var(--color-additionals-danger);
    color: var(--color-datepicker-date-active-text);

    &.mat-calendar-body-today {
      box-shadow: none;
    }
  }

  .mat-calendar-body-selected + .mat-calendar-body-cell-preview,
  .mat-calendar-body-in-range .mat-calendar-body-cell-preview {
    background-color: transparent;
  }

  .mat-calendar-body-selected + .mat-calendar-body-cell-preview {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .mat-calendar-body-range-start .mat-calendar-body-cell-preview {
    border-radius: $rd-2 0 0 $rd-2;
  }

  .mat-calendar-body-range-end .mat-calendar-body-cell-preview {
    border-radius: 0 $rd-2 $rd-2 0;
  }

  .mat-calendar-body-cell-container {
    .mat-calendar-body-cell.highlight-weekend {
      .mat-calendar-body-cell-content {
        color: var(--color-additionals-danger);
      }
    }
  }
}

.pu-date-picker__has-time-picker {
  .mat-calendar-body {
    height: rem(218px);
  }
}