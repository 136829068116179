:root.pu-dark-theme, .pt-mfe-shell.pu-dark-theme {
  // PU colors
  --pu-cr-danger: var(--color-additionals-danger);

  // PU Shadows
  --shadow-control-dropdown: var(--shadow-dropdown);

  // PU Button
  --pu-btn-txt: var(--text-icon-color-1);

  --pu-btn-primary-bg: var(--color-brand-primary);
  --pu-btn-primary-bg-hover: var(--color-brand-primary-2);
  --pu-btn-primary-bg-pressed: var(--color-brand-primary-3);
  --pu-btn-primary-txt-pressed: var(--text-icon-color-3);

  --pu-btn-secondary-bg: var(--background-color-3);
  --pu-btn-secondary-bg-hover: var(--color-brand-primary);
  --pu-btn-secondary-bg-pressed: var(--color-brand-primary-3);
  --pu-btn-secondary-txt-hover: var(--text-icon-color-1);
  --pu-btn-secondary-txt-pressed: var(--text-icon-color-3);

  --pu-btn-tertiary-bg: var(--background-color-4);
  --pu-btn-tertiary-bg-hover: var(--color-brand-primary);
  --pu-btn-tertiary-bg-pressed: var(--color-brand-primary-3);
  --pu-btn-tertiary-txt-pressed: var(--text-icon-color-3);

  --pu-btn-quaternary-txt: var(--text-icon-color-5);
  --pu-btn-quaternary-txt-hover: var(--text-icon-color-2);
  --pu-btn-quaternary-txt-pressed: var(--text-icon-color-3);

  --pu-btn-quinary-txt: var(--text-icon-color-1);
  --pu-btn-quinary-txt-hover: var(--color-brand-primary);
  --pu-btn-quinary-txt-pressed: var(--color-brand-primary-3);

  // PU slider controls
  --pu-slider-controls-icon-cr: var(--text-icon-color-5);
  --pu-slider-controls-prev-bg: var(--gradient-slider-controls-prev-1);
  --pu-slider-controls-next-bg: var(--gradient-slider-controls-next-1);
  --pu-slider-controls-hover: var(--text-icon-color-2);
  --pu-slider-pagination-cr: var(--text-icon-color-1);

  // PU Category tabs
  --pu-category-tabs-divider: var(--background-color-2);
  --pu-category-tab-icon: var(--text-icon-color-5);
  --pu-category-tab-icon-hover: var(--text-icon-color-1);
  --pu-category-tab-icon-active: var(--color-brand-primary);
  --pu-category-tab-txt: var(--text-icon-color-5);
  --pu-category-tab-txt-active: var(--text-icon-color-1);
  --pu-category-tab-txt-hover: var(--text-icon-color-1);
  --pu-category-tab-count-bg: var(--background-color-1);
  --pu-category-tab-highlight-bg-after: var(--color-brand-primary);
  --pu-category-tab-highlight-bg-before: var(--gradient-tab-highlighting);
  --pu-category-tab-filter-icon: brightness(0) saturate(100%) invert(60%) sepia(3%) saturate(1090%) hue-rotate(182deg) brightness(87%) contrast(88%);
  --pu-category-tab-filter-icon-hover: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(343deg) brightness(102%) contrast(101%);
  --pu-category-tab-filter-icon-active: brightness(0) saturate(100%) invert(28%) sepia(100%) saturate(5905%) hue-rotate(6deg) brightness(104%) contrast(105%);

  // PU Sub category tabs
  --pu-sub-category-tab-txt: var(--text-icon-color-5);
  --pu-sub-category-tab-txt-active: var(--text-icon-color-1);
  --pu-sub-category-tab-txt-hover: var(--text-icon-color-5);
  --pu-sub-category-tab-br: var(--background-color-4);
  --pu-sub-category-tab-br-hover: var(--gradient-border-hover);
  --pu-sub-category-tab-br-active: var(--gradient-sub-category-tab-br-active);
  --pu-sub-category-tab-bg: var(--gradient-sub-category-tab-bg);

  // PU Toggle
  --pu-toggle-bg: var(--background-color-5);
  --pu-toggle-bg-active: var(--color-brand-secondary);
  --pu-toggle-circle: var(--gradient-toggle-circle);
  --pu-toggle-circle-active: var(--gradient-toggle-circle-active);
  --pu-toggle-circle-bg: var(--gradient-toggle-circle-bg);
  --pu-toggle-circle-br: var(--background-color-1);
  --pu-toggle-sh: inset -1px 1px 1px var(--shadow-color-0);
  --pu-toggle-sh-circle: 1px 1px 1px var(--shadow-color-0);
  --pu-toggle-sh-circle-active: -1px 1px 1px var(--shadow-color-0);

  // PU Radio
  --pu-radio-bg: transparent;
  --pu-radio-br: var(--background-color-5);
  --pu-radio-label-txt: var(--text-icon-color-5);
  --pu-radio-sh-inner-right: inset -1px 1px 2px 0px var(--shadow-color-1);
  --pu-radio-sh-inner-left: inset 1px 1px 2px 0px var(--shadow-color-1);
  --pu-radio-sh-to-right: 1px 1px 1px 0px var(--shadow-color-0);

  // PU Checkbox
  --pu-checkbox-bg: transparent;
  --pu-checkbox-bg-active: var(--color-brand-secondary);
  --pu-checkbox-br: var(--background-color-4);
  --pu-checkbox-txt: var(--text-icon-color-5);
  --pu-checkbox-point: var(--text-icon-color-1);
  --pu-checkbox-sh: inset -1px 1px 2px var(--shadow-color-1);
  --pu-checkbox-sh-icon: drop-shadow(1px 1px 1px var(--shadow-color-0));
  --pu-checkbox-sh-active: inset 1px 1px 2px var(--shadow-color-1);

  // PU Input
  --pu-input-br: var(--background-color-7);
  --pu-input-br-active: var(--background-color-4);
  --pu-input-br-focus: var(--background-color-5);
  --pu-input-br-uneditable: var(--background-color-3x);
  --pu-input-bg-label: var(--background-color-1);
  --pu-input-txt-label: var(--text-icon-color-5);
  --pu-input-txt-label-uneditable: var(--text-icon-color-5);
  --pu-input-txt-label-focus: var(--text-icon-color-5);
  --pu-input-txt-placeholder: var(--text-icon-color-6);
  --pu-input-txt-filled: var(--text-icon-color-1);
  --pu-input-txt-uneditable: var(--text-icon-color-1);
  --pu-input-icon: var(--text-icon-color-6);
  --pu-input-sh-inset: var(--background-color-1);
  --pu-input-hint-txt: var(--text-icon-color-5);
  --pu-input-hint-icon: var(--color-brand-secondary);
  --pu-input-hint-bg: var(--background-color-1x);
  --pu-input-list-bg: var(--background-color-3);
  --pu-input-list-br: transparent;
  --pu-input-list-bg-hover: var(--background-color-2);
  --pu-input-list-bg-active: var(--background-color-2);
  --pu-input-list-txt: var(--text-icon-color-5);
  --pu-input-list-txt-hover: var(--text-icon-color-5);
  --pu-input-list-txt-active: var(--text-icon-color-1);
  --pu-input-list-txt-disabled: var(--text-icon-color-6);
  --pu-input-list-icon: var(--text-icon-color-6);
  --pu-input-list-icon-hover: var(--text-icon-color-5);

  // PU Search Input
  --pu-search-input-br: var(--background-color-4);
  --pu-search-input-txt: var(--text-icon-color-1);
  --pu-search-input-placeholder: var(--text-icon-color-6);
  --pu-search-input-icon: var(--text-icon-color-3);
  --pu-search-input-icon-md: var(--text-icon-color-1);

  // PU Dialog
  --pu-dialog-bg: var(--background-color-2);
  --pu-dialog-br: transparent;
  --pu-dialog-header-bg: var(--background-color-3);
  --pu-dialog-header-txt: var(--text-icon-color-1);
  --pu-dialog-message-txt: var(--text-icon-color-1);
  --pu-dialog-sub-message-txt: var(--text-icon-color-5);

  // PU Toast
  --pu-toast-bg: var(--background-color-3);
  --pu-toast-cr-info: var(--background-color-5);
  --pu-toast-cr-success: var(--color-additionals-success);
  --pu-toast-cr-warning: var(--color-additionals-warning);
  --pu-toast-cr-danger: var(--color-additionals-danger);
  --pu-toast-txt-header: var(--text-icon-color-1);
  --pu-toast-txt-body: var(--text-icon-color-3);
  --pu-toast-shadow: 0px 0px 10px var(--shadow-color-1);

  // PU Icon
  --pu-icon-close: var(--text-icon-color-5);
  --pu-icon-close-hover: var(--text-icon-color-1);

  // PU Overlay
  --pu-overlay-bg-darken: var(--color-transparent-overlay);

  // PU Empty
  --pu-empty-bg: var(--background-color-2);
  --pu-empty-icon-bg: var(--background-color-3);
  --pu-empty-title-txt: var(--text-icon-color-1);
  --pu-empty-message-txt: var(--text-icon-color-3);

  // PU Uploader
  --pu-uploader-br: var(--pu-input-br-active);
  --pu-uploader-br-uneditbale: var(--pu-input-br-uneditable);
  --pu-uploader-bg: var(--background-color-3);
  --pu-uploader-bg-hover: var(--background-color-3x);
  --pu-uploader-icon: var(--text-icon-color-1);
  --pu-uploader-spinner-cr: var(--color-brand-secondary);
  --pu-uploader-label-txt: var(--text-icon-color-5);
  --pu-uploader-label-txt-uneditbale: var(--text-icon-color-6);
  --pu-uploader-placeholder-txt: var(--text-icon-color-5);
  --pu-uploader-sub-placeholder-txt: var(--text-icon-color-6);
  --pu-uploader-file-bg: var(--background-color-3);
  --pu-uploader-file-bg-hover: var(--background-color-3x);
  --pu-uploader-file-txt: var(--text-icon-color-1);
  --pu-uploader-file-sub-txt: var(--text-icon-color-1);
  --pu-uploader-file-icon-remove: var(--text-icon-color-1);

  // PU Calendar
  --pu-calendar-border: var(--background-color-3);
  --pu-calendar-date-txt: var(--text-icon-color-3);
  --pu-calendar-date-weekend-txt: var(--text-icon-color-6);
  --pu-calendar-date-with-event-txt: var(--text-icon-color-1);
  --pu-calendar-date-with-event-bg: var(--color-brand-primary);
  --pu-calendar-day-with-event-bg-hover: var(--background-color-2);
  --pu-calendar-month-txt: var(--text-icon-color-3);
  --pu-calendar-weekday-txt: var(--text-icon-color-5);
  --pu-calendar-event-txt: var(--text-icon-color-2);
  --pu-calendar-event-default-txt: var(--text-icon-color-1);
  --pu-calendar-event-dot-bg: var(--color-brand-primary);
  --pu-calendar-popover-bg: var(--background-color-3);
  --pu-calendar-popover-event-txt: var(--text-icon-color-1);
  --pu-calendar-today-date-bg: var(--background-color-3);
  --pu-calendar-today-date-border: var(--gradient-calendar-today-date-border);

  // PU Calendars
  --pu-calendars-bg: var(--background-color-2);
  --pu-calendars-row-bg: var(--background-color-3);

  // PU Tags
  --pu-tags-color-bg: var(--background-color-3);
  --pu-tags-item-bg: var(--background-color-4);
  --pu-tags-popover-bg: var(--background-color-2);
  --pu-tags-popover-sh: var(--shadow-navbar);

  // PU Date Picker
  --pu-date-time-picker-card-bg: var(--background-color-2);

  // PU Highlighted text
  --pu-txt-highlight: var(--color-brand-secondary);

  // PU Fullscreen image viewer
  --pu-fullscreen-image-viewer-overlay-bg: var(--color-transparent-overlay);

  // PU Scale
  --pu-slider-bar-bg: var(--background-color-3);
  --pu-slider-bar-bg-selected: var(--background-color-5);

  // PU Increment
  --pu-increment-bg: var(--background-color-3);

  // PU Text expansion
  --pu-text-expansion-bg: var(--gradient-shadow-to-top);

  // PU Divider
  --pu-divider-horizontal: var(--gradient-divider-horizontal);
  --pu-divider-vertical: var(--gradient-divider-vertical);

  // PU Gallery
  --pu-gallery-preview-img-bg-hover: var(--background-color-4);

  // PU Froala
  --pu-fr-link-txt: var(--color-brand-secondary);
}
