@import 'geometry';

@mixin hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
}

@mixin text-ellipsis {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin text-line-clamp($count: 3) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $count;
  overflow: hidden;
}

@mixin rotate($deg, $speed, $origin: center) {
  transform: rotate($deg);
  transition: transform $speed;
  transform-origin: $origin;
}

@mixin custom-underline($offset: 4px) {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: $offset;
}

// Hides arrows for input of type number
@mixin input-arrows-hidden {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
}

@mixin gradient-border($borderWidth, $gradient) {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  border: $borderWidth solid transparent;
  background: var($gradient) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

@mixin horizontal-divider($left: 0, $right: 0, $color: var(--pu-divider-horizontal, var(--gradient-divider-horiz))) {
  content: "";
  position: absolute;
  left: $left;
  right: $right;
  height: 1px;
  background: $color;
  width: 100%;
}

@mixin vertical-divider($top: 0, $bottom: 0, $height: 100%, $color: var(--pu-divider-vertical, var(--gradient-divider-vert))) {
  content: "";
  position: absolute;
  top: $top;
  bottom: $bottom;
  width: 1px;
  background: $color;
  height: $height;
}

@mixin button-reset($width: true) {
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  background-color: none;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  letter-spacing: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  cursor: pointer;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @if ($width == true) {
    width: 100%;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  & > *,
  & > *:before,
  & > *:after {
    box-sizing: inherit;
  }
}
